import { FC, useContext } from "react";
import { UserContext } from "App";
import { Button, DatePicker, Flex, Form, Input, Popconfirm, Select, Space } from "antd";
import { isMobile } from "react-device-detect";
import ButtonClose from "Components/ButtonClose";
import API from "Api";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";

const Profile: FC<{ setUser: Function }> = ({ setUser }) => {
  const user = useContext(UserContext);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  const onSubmit = (values: any) => {
    API.user
      .update(values)
      .then(() => setUser((prev: any) => ({ ...prev, ...values })))
      .then(() => Cookies.set("user", JSON.stringify({ ...values, isEnglish: user?.isEnglish })));
  };

  return (
    <div>
      <Flex justify={"flex-end"}>
        <ButtonClose />
      </Flex>
      <Form
        initialValues={{ ...user, birthday: user?.birthday ? dayjs(user?.birthday) : undefined }}
        {...layout}
        title={"Профиль пользователя"}
        onFinish={onSubmit}
      >
        <Form.Item name={"email"} label={"Email"}>
          <Input readOnly />
        </Form.Item>
        <Form.Item name={"name"} label={"Username"} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={"familyName"} label={"Фамилия"}>
          <Input />
        </Form.Item>
        <Form.Item name={"givenName"} label={"Имя"}>
          <Input />
        </Form.Item>
        <Form.Item name={"phone"} label={"Телефон"}>
          <Input />
        </Form.Item>
        <Form.Item name={"sex"} label={"Пол"}>
          <Select
            options={[
              { label: "мужской", value: "male" },
              { label: "женский", value: "female" },
              { label: "не указан", value: null },
            ]}
          />
        </Form.Item>
        <Form.Item name={"birthday"} label={"Дата рождения"}>
          <DatePicker format={"DD.MM.YYYY"} />
        </Form.Item>

        <Form.Item wrapperCol={isMobile ? undefined : { offset: 4, span: 14 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
            <Popconfirm
              onConfirm={() =>
                API.user
                  .delete()
                  .then(() => setUser(null))
                  .then(() => {
                    Cookies.remove("user");
                    Cookies.remove("token");
                  })
              }
              title={"Вы действительно хотите удалить аккаунт? \nВесь прогресс нельзя будет восстановить."}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profile;
