import React, { FC, Fragment, useState } from "react";
import StorySentenceDictionary from "Components/StorySentence/StorySentenceDictionary";
import { SentenceTaskProps } from "./SentenceTask.type";
import useBlobAudios from "./StoryTask/useBlobAudios";
import TaskPanel from "Components/TaskPanel";
import { Button, Modal, Space } from "antd";
import { STATIC_URL } from "App.constants";
import { TaskType } from "App.types";
import ConversationTask from "./ConversationTask";
import { isMobile } from "react-device-detect";
import { AudioOutlined } from "@ant-design/icons";

const IntroTask: FC<SentenceTaskProps> = ({ sentence: taskSentence, lesson, completeLesson, onTaskComplete, onNext }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectTypeModal, setSelectTypeModal] = useState(false);
  const [activeType, setActiveType] = useState<TaskType>();

  const { sentences = [], id: storyId } = taskSentence.linkedStory || {};

  const audios = useBlobAudios({ sentences, rate: lesson.speechRate });

  return (
    <div>
      {!activeType && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: taskSentence.description ?? "" }} />

          <div dangerouslySetInnerHTML={{ __html: taskSentence.grammar?.content ?? "" }} />
          {sentences.map((sentence, idx) => (
            <Fragment key={sentence.id}>
              <StorySentenceDictionary storyId={storyId} sentence={sentence} audio={audios?.[idx]} />
              <div />
            </Fragment>
          ))}
        </div>
      )}

      {!activeType && (
        <TaskPanel justify={"center"}>
          <Space>
            <Button type={"link"} shape={"round"} onClick={() => setConfirmModal(true)}>
              skip lesson
            </Button>
            <Button type={"primary"} shape={"round"} onClick={() => onTaskComplete().then(() => onNext())}>
              Let's go!
            </Button>
          </Space>
        </TaskPanel>
      )}

      <Modal
        open={confirmModal}
        onOk={() => setConfirmModal(false)}
        onCancel={() => {
          setConfirmModal(false);
          setSelectTypeModal(true);
        }}
        title={"Уже знаешь как переводятся эти слова и предложения и готов подтвердить свои знания?"}
        okText={"Вернуться в урок"}
        cancelText={"Да, я уже все это знаю"}
      >
        <img src={`${STATIC_URL}/covers/man_finish.svg`} alt={""} />
      </Modal>

      <Modal
        onCancel={() => setSelectTypeModal(false)}
        open={selectTypeModal}
        title={"Готов переводить голосом?"}
        footer={
          <Space direction={isMobile ? "vertical" : "horizontal"}>
            <Button onClick={() => setSelectTypeModal(false)} type={"primary"}>
              Вернуться в урок
            </Button>
            <Button
              onClick={() => {
                setSelectTypeModal(false);
                setActiveType(TaskType.Translate);
              }}
            >
              Нет, буду печатать
            </Button>
            <Button
              danger
              icon={<AudioOutlined />}
              onClick={() => {
                setSelectTypeModal(false);
                setActiveType(TaskType.VoiceTranslate);
              }}
            >
              Да
            </Button>
          </Space>
        }
      >
        <img src={`${STATIC_URL}/covers/speak.svg`} alt={""} />
      </Modal>

      {activeType && (
        <ConversationTask
          sentence={taskSentence}
          activeType={activeType}
          backToLesson={() => setActiveType(undefined)}
          onTaskComplete={() =>
            new Promise(() => {
              setActiveType(undefined);
              completeLesson?.();
            })
          }
          lesson={lesson}
          onNext={() => {}}
          setTry={() => {}}
          setDictOpened={() => {}}
          showComments={() => {}}
        />
      )}
      <div style={{ height: 60 }} />
    </div>
  );
};

export default IntroTask;
