import ReactPlayer from "react-player";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styles from "./VideoBackground.module.scss";
import { OnProgressProps } from "react-player/base";
import { round } from "lodash";
import { Flex, Spin } from "antd";

type Props = {
  text: string;
  isActive: boolean;
  url: string;
  start?: number;
  end?: number;
  onFinish: Function;
  autoPlay?: boolean;
};

const VideoBackgroundPlayer: FC<Props & PropsWithChildren> = ({ text, autoPlay = true, isActive, url, start = 0, end, onFinish }) => {
  const [isPlaying, setIsPlaying] = useState(isActive && autoPlay);
  const [loading, setLoading] = useState(true);
  const [duration, setDuration] = useState(0);

  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    playerRef.current?.seekTo(start);
    setIsPlaying(isActive && autoPlay);
  }, [isActive, start, autoPlay]);

  const onProgress = useCallback(
    (s: OnProgressProps) => {
      if (s.playedSeconds >= (end || Infinity) && round(s.playedSeconds, 1) !== s.playedSeconds) {
        setIsPlaying(false);
        onFinish();
      }
    },
    [end, onFinish],
  );

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();
    if (player && duration && text?.trim()) {
      let text1 = player.textTracks[0] || player.addTextTrack("captions", "English", "en");
      text1.mode = "showing";
      text1.addCue(new VTTCue(0, 99, text));
    } else if (player?.textTracks?.length) {
      player.textTracks[0].mode = "disabled";
    }
  }, [duration, text]);

  return (
    <div
      onClick={() => setIsPlaying((prev) => !prev)}
      style={{ display: isActive ? "block" : "none" }}
      className={cx(styles.videoBackground, { [styles.videoTask_mobile]: isMobile })}
    >
      {loading && (
        <Flex align={"center"} justify={"center"}>
          <Spin size={"large"} />
        </Flex>
      )}
      <ReactPlayer
        progressInterval={100}
        onProgress={onProgress}
        playsinline={true}
        ref={playerRef}
        onDuration={setDuration}
        onError={() => setIsPlaying(false)}
        onReady={() => setLoading(false)}
        onEnded={() => onFinish()}
        playing={isPlaying && isActive}
        width={isMobile ? "100%" : ""}
        height={isMobile ? 280 : "100%"}
        controls={false}
        url={url}
      />
    </div>
  );
};

export default VideoBackgroundPlayer;
