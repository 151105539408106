import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SkipTags, STATIC_URL } from "App.constants";
import { delay, findIndex, flatten, last, uniqBy } from "lodash";
import { Button, Flex, Image, Segmented, Space } from "antd";
import { LessonType, MarkerType, SentenceType, StatusType, StoryType, TaskType } from "App.types";
import useDeepgram from "Hooks/useDeepgram";
import { getCompletedSlate } from "App.helpers";
import StoryMaskedDragDrop from "./StoryMaskedDragDrop";
import styles from "./StoryTask.module.scss";
import Dictionary from "Components/Dictionary";
import StorySentenceDragDrop from "./StorySentenceDragDrop";
import StorySentence from "Components/StorySentence";
import StoryDialogSentence from "Components/StoryDialogSentence";
import { Element, scroller } from "react-scroll";
import VideoStoryTask from "../VideoTask";
import StoryMaskedTask from "Pages/Books/Page/StoryMaskedTask";
import { AudioOutlined, BookOutlined, CheckOutlined, PauseOutlined, SoundFilled } from "@ant-design/icons";
import cx from "classnames";
import TaskPanel from "Components/TaskPanel";
import StoryMatchTask from "./StoryMatchTask";
import StorySentenceDictionary from "Components/StorySentence/StorySentenceDictionary";
import { isMobile } from "react-device-detect";
import useBlobAudios from "./useBlobAudios";
import { useSpeechCheck } from "Hooks/useSpeechCheck";

const StoryTask: FC<{
  story: StoryType;
  lesson: LessonType;
  activeType?: TaskType;
  onTaskComplete: () => any;
  onNext: Function;
  media?: string;
  autoPlay?: boolean;
  markers?: MarkerType[];
}> = ({ markers, autoPlay = true, media, story, lesson, onTaskComplete, activeType = TaskType.StoryListen, onNext }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [source, setSource] = useState<string>("");
  const [selectedSent, setSelectedSent] = useState<number>();
  const [activeSentIdx, setActiveSentIdx] = useState<number>(autoPlay ? -1 : story.sentences?.length);
  const [activeWordIdx, setActiveWordIdx] = useState<number>(-1);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [isDictOpened, setDictOpened] = useState(false);

  const { sentences, title, id: storyId } = story;

  const uniqSentences = useMemo(
    () =>
      uniqBy(
        sentences.filter((s) => s.text),
        "text",
      ),
    [sentences],
  );

  const allTags = useMemo(() => flatten(sentences.map((s) => s.tags)), [sentences]);

  const { start, stop, reset, transcript: speechText } = useDeepgram({ setStatus, tags: allTags });

  const sentence = useMemo<SentenceType | undefined>(() => sentences[activeSentIdx], [sentences, activeSentIdx]);

  const onCheckRef = useRef<{ onCheck: () => any }>(null);

  useEffect(() => {
    if (story.type === "dialog" && activeSentIdx >= 0 && activeSentIdx < sentences.length) {
      scroller.scrollTo(`${activeSentIdx}`, { smooth: true, isDynamic: true });
    }
  }, [activeSentIdx, sentences.length, story.type]);

  const filteredRightTags = useMemo(
    () => getCompletedSlate(sentence?.tags ?? [], sentence?.text ?? "").filter((el) => el.word && !SkipTags.includes(el.word)),
    [sentence],
  );

  useEffect(() => {
    if (activeWordIdx >= last<any>(filteredRightTags)?.idx) {
      delay(() => {
        setActiveSentIdx((prev) => prev + 1);
        setActiveWordIdx(-1);
        reset();
      }, 600);

      if (activeSentIdx >= sentences.length - 1) {
        stop();
        setStatus(StatusType.Completed);
        onTaskComplete();
      }
    }
  }, [reset, filteredRightTags, onTaskComplete, sentence?.id, stop, activeWordIdx, sentences.length, activeSentIdx]);

  useSpeechCheck({
    filteredRightTags,
    setCompletedTagIdx: setActiveWordIdx,
    completedTagIdx: activeWordIdx,
    sentence,
    textFromSpeech: speechText,
  });

  const onClickRecording = () => {
    if ([StatusType.IsRecording, StatusType.Loading].includes(status)) {
      stop();
      setActiveWordIdx(-1);
    } else {
      // setActiveWordIdx(0);
      setActiveSentIdx((prevState) => (prevState > 0 ? prevState : 0));
      start();
    }
  };

  const audio = useMemo(() => {
    if (title && story.type !== "video") {
      let aud: HTMLAudioElement | undefined = new Audio(`${STATIC_URL}/stories/${title}/audio.m4a`);
      aud.onended = () => setActiveSentIdx((prev) => prev + 1);
      aud.onerror = (e: any) => (e.target.src.includes(".mp3") ? undefined : (e.target.src = `${STATIC_URL}/stories/${title}/audio.mp3`));
      return aud;
    }
    return undefined;
  }, [story.type, title]);

  useEffect(() => {
    audio?.addEventListener("play", () => setAudioStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setAudioStatus(StatusType.Empty));
    audio?.addEventListener("ended", () => setStatus(StatusType.Completed));

    return () => audio?.pause();
  }, [audio]);

  useEffect(() => {
    // setActiveSentIdx(activeType === "listen" ? Infinity : 0);
    setStatus(StatusType.Empty);
  }, [activeType]);

  const timeListener = useRef<any>(null);

  const audios = useBlobAudios({ sentences, rate: lesson.speechRate });

  useEffect(() => {
    if (autoPlay) setActiveSentIdx(0);
  }, [autoPlay]);

  useEffect(() => {
    audios.forEach((aud, idx) => {
      aud.onplay = () => setAudioStatus(StatusType.isPlaying);
      aud.onended = ({ type }) => {
        if (type === "ended")
          setActiveSentIdx((prev) => {
            if (sentences.length <= prev + 1 && [TaskType.StoryListen, TaskType.Dictionary].includes(activeType)) {
              onTaskComplete();
              setStatus(StatusType.Completed);
            }
            setAudioStatus(StatusType.Empty);
            const next = prev + (sentences[idx + 1]?.text ? 1 : 2); // 2 - если есть пустая строка между предложениями
            if ([TaskType.StoryListen, TaskType.Dictionary].includes(activeType) || markers?.includes("noTranslate")) {
              delay(() => audios[next]?.play(), 400);
            }
            return next;
          });
      };
    });
  }, [audios, autoPlay, activeType, onTaskComplete, sentences, markers]);

  const activeAudio = useMemo(() => audios?.[activeSentIdx], [audios, activeSentIdx]);

  const play = useCallback(
    (
      from: number = sentences[0].transcripts?.[0]?.start,
      to: number = last(last(sentences)?.transcripts)?.end || 0,
      playOne: boolean = false,
    ) => {
      if (!audio || audio?.networkState !== 1) {
        return;
      }

      const currentTime = !audio?.currentTime || audio.currentTime >= to || playOne ? from : audio.currentTime;
      if (currentTime) audio.currentTime = currentTime;
      audio?.play();

      audio.removeEventListener("timeupdate", timeListener.current);

      timeListener.current = ({ target: { paused } }: any) => {
        const { currentTime } = audio;

        if (currentTime >= to) {
          audio.pause();

          if (!playOne) {
            if (activeType === TaskType.StoryListen) {
              setStatus(StatusType.Completed);
              onTaskComplete();
            }
            setSelectedSent(-1);
          }
        }

        if (paused) {
          return delay(() => {
            setSelectedSent((prevState) => (playOne ? undefined : prevState));
            setActiveWordIdx(-1);
          }, 500);
        }
        let activeIdx = sentences.findIndex(
          ({ transcripts }) => transcripts[0]?.start <= currentTime && (last(transcripts)?.end || 0) >= currentTime,
        );
        if (activeIdx === -1 && !playOne) {
          activeIdx = sentences.findIndex(({ transcripts }) => transcripts[0]?.start >= currentTime);
        }

        if (activeIdx >= 0 && !playOne) {
          setActiveSentIdx(activeIdx);
        }

        if (activeIdx > -1) {
          setSelectedSent(sentences[activeIdx]?.id);
          const wordIdx = findIndex(sentences[activeIdx].transcripts, (el) => el.end >= currentTime && !SkipTags.includes(el.text));
          setActiveWordIdx(wordIdx > -1 ? wordIdx : -1);
        }
      };

      audio.addEventListener("timeupdate", timeListener.current);
    },
    [activeType, audio, onTaskComplete, sentences],
  );

  useEffect(() => {
    if (activeSentIdx === -1) {
      setActiveSentIdx(0);
    }
  }, [activeSentIdx, activeAudio]);

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete();
  }, [onTaskComplete]);

  return (
    <div className={cx(styles.storyTask, { [styles.storyTask_mobile]: isMobile })}>
      {[TaskType.StoryRead, TaskType.StoryListen].includes(activeType) && story.type !== "video" && (
        <Segmented className={styles.segmented} onChange={(v) => setSource(v as string)} options={["Оригинал", "Перевод", "Все вместе"]} />
      )}

      {[".jpg", ".svg", ".gif"].includes(media?.slice(-4) ?? "") &&
        ![TaskType.MaskedDragDrop, TaskType.StoryDragDrop].includes(activeType) && (
          <Image
            wrapperClassName={cx(styles.image)}
            onError={(e: any) => (e.target.style.visibility = "hidden")}
            src={`${STATIC_URL}/media/${media}`}
            // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
            alt={media}
            preview={{ toolbarRender: () => null }}
          />
        )}

      {media?.includes(".mp3") && <audio style={{ marginBottom: 10 }} src={`${STATIC_URL}/media/${media}`} controls />}

      <div className={styles.storyTask__text}>
        {story.type === "dialog" && [TaskType.StoryListen].includes(activeType) && (
          <Flex vertical style={{ maxWidth: 680 }} gap={10}>
            {sentences.slice(0, activeSentIdx + 1).map((sentence, idx) => (
              <Element name={`${idx}`} key={sentence.id}>
                <Flex justify={sentence.isLeft ? "flex-start" : "flex-end"}>
                  <div style={{ maxWidth: "90%" }}>
                    <StoryDialogSentence
                      speechRate={lesson.speechRate}
                      storyId={storyId}
                      hidden={activeSentIdx < idx}
                      active={selectedSent === sentence.id || idx === activeSentIdx}
                      activeWordIdx={selectedSent === sentence.id ? activeWordIdx : undefined}
                      audio={audios?.[idx]}
                      sentence={sentence}
                      showTranslate={source === "Перевод"}
                      withTranslate={source === "Все вместе"}
                      showText={["Оригинал", "Перевод"].includes(source)}
                    />
                  </div>
                </Flex>
              </Element>
            ))}
          </Flex>
        )}

        {activeType === TaskType.Video && (
          <VideoStoryTask status={status} sentences={sentences} onTaskComplete={onComplete} story={story} autoPlay={autoPlay} />
        )}

        {[TaskType.Dictionary].includes(activeType) &&
          sentences.map((sentence, idx) => (
            <Fragment key={sentence.id}>
              <StorySentenceDictionary
                storyId={storyId}
                active={selectedSent === sentence.id || idx === activeSentIdx}
                play={play}
                sentence={sentence}
                // marginLeft={story.type === "card" || (sentences.some((s) => !s.wrap) ? idx === 0 || sentences[idx - 1].wrap : false)}
                audio={audios?.[idx]}
              />
              <div />
            </Fragment>
          ))}

        {(activeType === TaskType.StoryRead || ([TaskType.StoryListen].includes(activeType) && !["dialog"].includes(story.type))) &&
          sentences.map((sentence, idx) => (
            <Fragment key={sentence.id}>
              <StorySentence
                storyId={storyId}
                active={selectedSent === sentence.id || idx === activeSentIdx}
                activeWordIdx={selectedSent === sentence.id ? activeWordIdx : undefined}
                completedWordIdx={activeType === TaskType.StoryRead ? activeWordIdx : undefined}
                play={play}
                sentence={sentence}
                marginLeft={story.type === "card" || (sentences.some((s) => !s.wrap) ? idx === 0 || sentences[idx - 1].wrap : false)}
                showTranslate={source === "Перевод"}
                withTranslate={source === "Все вместе"}
                showText={["Оригинал", "Перевод"].includes(source)}
                audio={audios?.[idx]}
              />
              {(story.type === "card" || sentences[idx]?.wrap) && <div />}
            </Fragment>
          ))}
      </div>

      {TaskType.MaskedDragDrop === activeType && (
        <StoryMaskedDragDrop
          story={story}
          media={media}
          isCompleted={status === StatusType.Completed}
          onTaskComplete={onComplete}
          sentences={sentences}
          showTranslate={!markers?.includes("noTranslate")}
        />
      )}

      {TaskType.StoryMasked === activeType && (
        <StoryMaskedTask
          showTranslate={!markers?.includes("noTranslate")}
          showTooltip={!markers?.includes("hideMaskedHint")}
          story={story}
          sentences={sentences}
          audio={audio}
          onNext={onNext}
          onTaskComplete={onComplete}
        />
      )}

      {[TaskType.Match].includes(activeType) && (
        <StoryMatchTask isCompleted={status === StatusType.Completed} onTaskComplete={onComplete} sentences={uniqSentences} />
      )}

      {[TaskType.StoryDragDrop].includes(activeType) && (
        <StorySentenceDragDrop
          ref={onCheckRef}
          showTranslate={!markers?.includes("noTranslate")}
          isCompleted={status === StatusType.Completed}
          onTaskComplete={onComplete}
          sentences={uniqSentences}
        />
      )}

      {story.type !== "video" && <div className={styles.placeholder} />}
      {activeType !== TaskType.StoryMasked && (
        <TaskPanel>
          <Button style={{ visibility: "hidden" }}></Button>

          <Space>
            {(status === StatusType.Completed || ![TaskType.StoryDragDrop].includes(activeType)) && (
              <Button
                disabled={status !== StatusType.Completed}
                icon={<CheckOutlined />}
                className={cx(styles.btn_next)}
                type={"primary"}
                shape={"round"}
                onClick={() => onNext()}
              >
                далее
              </Button>
            )}

            {activeType === TaskType.StoryRead && (
              <Button
                disabled={status === StatusType.Completed}
                onClick={onClickRecording}
                className={styles.record}
                type={status === StatusType.Completed ? "default" : "primary"}
                icon={<AudioOutlined />}
                shape="circle"
                data-recording={status === StatusType.IsRecording}
                loading={status === StatusType.Loading}
              />
            )}

            {status !== StatusType.Completed && [TaskType.StoryDragDrop].includes(activeType) && (
              <Button type={"primary"} shape={"round"} onClick={() => onCheckRef.current?.onCheck()}>
                проверить
              </Button>
            )}

            {(markers?.includes("noTranslate") || [TaskType.StoryListen, TaskType.Dictionary].includes(activeType)) && (
              <Button
                type={status === StatusType.Completed ? "default" : "primary"}
                icon={audioStatus === StatusType.isPlaying ? <PauseOutlined /> : <SoundFilled />}
                onClick={
                  audioStatus === StatusType.isPlaying
                    ? () => {
                        audio?.pause();
                        activeAudio?.pause();
                        setAudioStatus(StatusType.Empty);
                      }
                    : () => {
                        if (activeSentIdx > sentences.length - 1) {
                          setActiveSentIdx(0);
                        }
                        activeAudio?.play();
                        play();
                      }
                }
              />
            )}
          </Space>
          <Button icon={<BookOutlined />} className={styles.btn_play} onClick={() => setDictOpened(true)} />
        </TaskPanel>
      )}

      <Dictionary storyId={storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <div className={styles.placeholder} />
    </div>
  );
};

export default StoryTask;
