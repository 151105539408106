import { Button, Drawer, Flex } from "antd";
import { isMobile } from "react-device-detect";
import React, { FC, PropsWithChildren } from "react";
import styles from "./HintDrawer.module.scss";

const HintDrawer: FC<
  PropsWithChildren & {
    text: string | undefined;
    open: boolean;
    onClose?: () => any;
    buttonText?: string;
  }
> = ({ children, onClose, buttonText = "OK", text, open }) => {
  return (
    <Drawer closable={false} height={"fit-content"} onClose={onClose} mask={false} placement="bottom" open={open}>
      <Flex justify={"center"}>
        <div style={{ width: "100%", maxWidth: 1060 }}>
          <Flex justify={"space-between"} style={{ flexDirection: isMobile ? "column" : undefined }}>
            <div className={styles.hintHtml} style={{ marginBottom: isMobile ? 10 : 0 }} dangerouslySetInnerHTML={{ __html: text ?? "" }} />
            {children || (
              <Button size={isMobile ? "middle" : undefined} type={"primary"} onClick={onClose}>
                {buttonText}
              </Button>
            )}
          </Flex>
        </div>
      </Flex>
    </Drawer>
  );
};

export default HintDrawer;
