import { Badge, Flex, Rate, Tooltip } from "antd";
import { LessonType } from "App.types";
import { FC, useContext } from "react";
import { Levels, STATIC_URL } from "App.constants";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import styles from "./LessonsItem.module.scss";
import { CheckOutlined, CrownTwoTone } from "@ant-design/icons";
import { UserContext } from "App";

const LessonsItem: FC<{ isCompleted: boolean; lesson: LessonType; stars?: number }> = ({
  isCompleted,
  stars,
  lesson: {
    level = 0,
    story: { picture, type, wordCount, title, translate },
    id,
    description,
    type: lessonType,
  },
}) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  return (
    <Badge.Ribbon
      rootClassName={cx(styles.lessonCard, { [styles.mobile]: isMobile, [styles.lessonCard__completed]: isCompleted })}
      className={cx({ [styles.hidden]: !Levels[level]?.title })}
      style={{ fontSize: isMobile ? 12 : undefined }}
      text={Levels[level]?.title}
      color={Levels[level]?.color}
    >
      <div
        id={`${id}`}
        className={styles.lessonCard__container}
        // style={{ backgroundImage: `url(${STATIC_URL}/lessons/${story.title?.toLowerCase()}.svg)` }}
        // style={{ backgroundImage: `url(${STATIC_URL}/stories/${story.title}/images/cover.svg)` }}
        onClick={() => navigate(`lessons/${lessonType || type}/${id}`, { state: { goBack: true } })}
      >
        <div className={cx(styles.content, { [styles.content__mobile]: isMobile })}>
          <Flex justify={"center"} className={styles.coverWrapper}>
            <img
              className={cx(styles.cover, { [styles.cover__jpg]: picture?.includes("jpg") })}
              alt={""}
              src={picture ? `${STATIC_URL}/media/${picture}` : `${STATIC_URL}/covers/${lessonType || type}_${user?.lang}.svg`}
            />
          </Flex>

          <div>
            {isCompleted ? (
              <CheckOutlined />
            ) : (
              !!wordCount && (
                <div className={styles.desc}>
                  <CrownTwoTone />
                  {` ${wordCount}`}
                </div>
              )
            )}
            <div className={styles.title}>{description || title}</div>
            <div className={styles.desc}>{description ? title : translate}</div>
            {stars && (
              <Tooltip title={"Оценка выполнения задания"}>
                <Rate className={styles.stars} allowHalf disabled defaultValue={stars} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      {/*{!!story.wordCount && <Tag bordered={false}>кол-во слов: {story.wordCount}</Tag>}*/}
    </Badge.Ribbon>
  );
};

export default LessonsItem;
