import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button } from "antd";
import API from "Api";
import cx from "classnames";
import { withCustomLogic } from "App.helpers";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import { SentenceTaskProps } from "./SentenceTask.type";
import styles from "./SentenceTask.module.scss";
import TaskPanel from "Components/TaskPanel";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const FeedbackTextTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, translate, description },
  lesson,
  onTaskComplete,
  onNext,
  children,
  alignCenter = false,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, initialValue, { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [editor],
  );

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete(id);
  }, [onTaskComplete, id]);

  const onSubmit = useCallback(() => {
    API.feedback
      .save({
        answer: editor.children[0].children[0].text,
        question: text || translate || description || "",
        lessonId: lesson.id,
        type: "text",
      })
      .then(onComplete);
  }, [editor.children, lesson.id, onComplete, text, translate, description]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
  }, [sentence.id, editor, setInitialState]);

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onReset = () => {
    setInitialState(status === StatusType.Editing);

    ReactEditor.focus(editor);
  };

  // console.log("sentenceTask", activeType, editor.children[0]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed"}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  onChange={console.log}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          {!noTranslate && <div className={styles.translate}>{translate}</div>}
        </div>
      </div>

      <TaskPanel justify={"center"}>
        {status === StatusType.Completed ? (
          <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
            далее
          </Button>
        ) : (
          <Button type={"primary"} shape={"round"} onClick={onSubmit}>
            ответить
          </Button>
        )}
      </TaskPanel>
    </div>
  );
};

export default FeedbackTextTask;
