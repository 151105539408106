import styles from "./TaskPanel.module.scss";
import { FC, PropsWithChildren } from "react";
import { Flex, FlexProps } from "antd";
import cx from "classnames";
import { useLocation } from "react-router-dom";

const TaskPanel: FC<FlexProps & PropsWithChildren> = ({ children, justify = "space-between", ...rest }) => {
  const location = useLocation();
  const positionAbsolute = location.pathname.includes("/review/");

  return (
    <Flex
      align={"center"}
      justify={justify}
      className={cx(styles.taskPanel, [{ [styles.taskPanel_absolute]: positionAbsolute }])}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default TaskPanel;
