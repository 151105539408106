import { Modal } from "antd";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import useScript from "Components/useScript";
import axios, { AxiosInstance } from "axios";
import { API_URL } from "App.constants";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";

const LoginModal: FC<{ axiosInstance: AxiosInstance; open: boolean; setUser: Function } & PropsWithChildren> = ({
  axiosInstance,
  open,
  setUser,
}) => {
  const [once, setOnce] = useState(true);
  const queryClient = useQueryClient();

  const handleYandexResponse = useCallback(
    (token: string) => {
      axios
        .get(`${API_URL}/users/afterLogin`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: false })
        .then(({ data }) => {
          setUser({ ...data, isEnglish: data.lang === "en" });
          axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;

          Cookies.set("token", token);
          Cookies.set("user", JSON.stringify({ ...data, isEnglish: data.lang === "en" }));
          queryClient.setDefaultOptions({ queries: { enabled: true } });
          queryClient.resetQueries({ fetchStatus: "idle" }).then();
        });
    },
    [axiosInstance, queryClient, setUser],
  );

  function handleCredentialResponse(response: any) {
    const { credential } = response;

    axios
      .get(`${API_URL}/users/afterLogin`, { headers: { Authorization: `Bearer ${credential}` }, withCredentials: false })
      .then(({ data }) => {
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${credential}`;

        Cookies.set("token", credential);
        Cookies.set("user", JSON.stringify({ ...data, isEnglish: data.lang === "en" }));
        setUser({ ...data, isEnglish: data.lang === "en" });
        queryClient.setDefaultOptions({ queries: { enabled: true } });
        queryClient.resetQueries({ fetchStatus: "idle" }).then();
      });
  }

  useGoogleOneTapLogin({
    onError: console.log,
    auto_select: true,
    cancel_on_tap_outside: false,
    onSuccess: handleCredentialResponse,
    disabled: !open,
    // promptMomentNotification: (moment) => setShowModal(!moment.isDisplayed()),
  });

  const initYandexLogin = useCallback(() => {
    // @ts-ignore
    window.YaAuthSuggest.init(
      {
        client_id: "d18166ba3d9e417d9f9035d6bfd93bbf",
        response_type: "token",
        redirect_uri: `${window.location.origin}/yandex`,
      },
      window.location.origin,
      {
        view: "button",
        parentId: "yandexButton",
        buttonSize: "m",
        buttonView: "additional",
        buttonTheme: "light",
        buttonBorderRadius: "5",
        buttonIcon: "ya",
      },
    )
      .then(({ handler }: any) => handler())
      .then((data: { access_token: string; expires_in: string }) => {
        handleYandexResponse(data.access_token);
      })
      .catch((error: any) => console.log("Обработка ошибки", error));
  }, [handleYandexResponse]);

  useEffect(() => {
    if (open && !once) {
      initYandexLogin();
    }
  }, [open, once, initYandexLogin]);

  useScript("https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js", () => {
    if (open && once) {
      setOnce(false);
      initYandexLogin();
    }
  });

  return (
    <Modal
      closable={false}
      footer={
        <div>
          <GoogleLogin size={"large"} onSuccess={handleCredentialResponse} useOneTap cancel_on_tap_outside={false} />
          <div style={{ margin: "10px 0" }} id="yandexButton" />
        </div>
      }
      open={open}
      title="Welcome to Lexoid!"
    >
      {"Для продолжения работы требуется авторизоваться с помощью учетной записи Google или Yandex"}
    </Modal>
  );
};

export default LoginModal;
