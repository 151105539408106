import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { flatten, sample } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { MediaType } from "App.types";

const SUCCESS_STORY_ID = 643;
const FAIL_STORY_ID = 642;

const useFeedbackVideos = () => {
  const [successList, setSuccessList] = useState<MediaType[]>([]);
  const [failedList, setFailedList] = useState<MediaType[]>([]);

  const { data: successMedia = [] } = useQuery({
    queryKey: ["sentences", SUCCESS_STORY_ID],
    queryFn: () => API.sentence.getListByStoryId(SUCCESS_STORY_ID),
    staleTime: Infinity,
    select: (data) => flatten(data.map((sentence) => sentence.medias)),
  });

  useEffect(() => {
    setSuccessList(successMedia);
  }, [successMedia]);

  const { data: failedMedia = [] } = useQuery({
    queryKey: ["sentences", FAIL_STORY_ID],
    queryFn: () => API.sentence.getListByStoryId(FAIL_STORY_ID),
    staleTime: Infinity,
    select: (data) => flatten(data.map((sentence) => sentence.medias)),
  });

  useEffect(() => {
    setFailedList(failedMedia);
  }, [failedMedia]);

  const getNextSuccess = useCallback(() => {
    const next = sample(successList);
    setSuccessList((prev) => (prev.length === 1 ? successMedia : prev.filter((m) => m !== next)));
    return next;
  }, [successList, successMedia]);

  const getNextFailed = useCallback(() => {
    const next = sample(failedList);
    setFailedList((prev) => (prev.length === 1 ? failedMedia : prev.filter((m) => m !== next)));
    return next;
  }, [failedList, failedMedia]);

  return { getNextSuccess, getNextFailed };
};

export default useFeedbackVideos;
