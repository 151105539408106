import { Progress, ProgressProps, Space, Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { GrammarType, ProgressType } from "App.types";
import cx from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { TimelineItemProps } from "antd/es/timeline/TimelineItem";
import LessonsModule from "./LessonsModule";
import LessonsItem from "./LessonsItem";
import { useMemo, useState } from "react";
import { getStepColor } from "./Lessons.conts";
import styles from "./Lessons.module.scss";
import GrammarPanel from "Components/GrammarPanel";
import { isMobile } from "react-device-detect";
import { STATIC_URL } from "App.constants";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const Lessons = () => {
  const [grammar, setGrammar] = useState<GrammarType | undefined>(undefined);
  const { course: courseTitle } = useParams();

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", courseTitle],
    queryFn: () => API.lesson.getList(courseTitle),
  });

  const { data: course } = useSuspenseQuery({
    queryKey: ["courses"],
    queryFn: API.course.getList,
    select: (data) => data.find((c) => c.title === courseTitle),
  });

  const rootLessons = useMemo(() => lessons.filter((l) => !l.moduleId), [lessons]);

  const { data: progress } = useSuspenseQuery({
    queryKey: ["progress"],
    queryFn: () => API.progress.getList(),
    select: (data) => data.reduce<{ [id: number]: ProgressType }>((acc, value) => ({ ...acc, [value?.lesson?.id || 0]: value }), {}),
  });

  const { data: allProgress = [] } = useSuspenseQuery({
    queryKey: ["progress"],
    queryFn: () => API.progress.getList(),
  });

  const courseProgress = useMemo(
    () =>
      (allProgress.filter((pr) => lessons?.some((l) => l.id === pr.lesson.id) && pr.status === "completed").length / lessons.length) * 100,
    [allProgress, lessons],
  );

  const getStepIcon = (percent?: number) => {
    if (percent) {
      return <Progress status={percent !== 100 ? "exception" : undefined} type={"circle"} percent={percent} size={28} />;
    }

    return <ClockCircleOutlined style={{ fontSize: 28 }} />;
  };

  const navigate = useNavigate();

  const items: TimelineItemProps[] = rootLessons.map((lesson, idx) => {
    const isModule = lesson.type === "module";
    const childLessons = lessons.filter((l) => l.moduleId === lesson.id);
    const completedLessons = childLessons.filter((l) => progress?.[l.id]?.status === "completed").length;
    const modulePercent = isModule ? (1 - (childLessons.length - completedLessons) / childLessons.length) * 100 : 0;

    const isCompleted = isModule ? childLessons.length === completedLessons : progress?.[lesson.id]?.status === "completed";

    return {
      dot: getStepIcon(isModule ? modulePercent : progress?.[lesson.id]?.percent),
      color: getStepColor(isModule ? modulePercent : progress?.[lesson.id]?.percent),
      className: cx(styles.item, {
        [styles.item_nextCompleted]:
          lessons[idx + 1] && progress?.[lessons[idx + 1].id]?.status === "completed" && progress?.[lesson.id]?.status === "completed",
      }),
      children: isModule ? (
        <LessonsModule
          openGrammarPanel={setGrammar}
          progress={progress}
          lessons={lessons}
          isCompleted={isCompleted}
          key={lesson.id}
          lesson={lesson}
        />
      ) : (
        <LessonsItem isCompleted={isCompleted} key={lesson.id} stars={progress?.[lesson.id]?.stars} lesson={lesson} />
      ),
    };
  });

  return (
    <div className={cx(styles.lessons, { [styles.lessons_mobile]: isMobile })}>
      <div className={styles.course}>
        <div className={styles.picture} style={{ backgroundImage: `url(${STATIC_URL}/covers/${course?.picture})` }}></div>
        <div className={styles.course__right}>
          <div className={styles.course__title} onClick={() => navigate("/courses")}>
            {courseTitle}
          </div>
          <div className={styles.course__description}>{course?.translate}</div>
          <div className={styles.course__html} dangerouslySetInnerHTML={{ __html: course?.grammar?.content || "" }}></div>

          <Progress className={styles.courseProgress} size={"small"} percent={courseProgress} showInfo={false} strokeColor={twoColors} />
        </div>
      </div>

      <Space id={"lessons"} direction={"vertical"}>
        <Timeline items={items} />
        {/*{rootLessons.map((lesson) =>*/}
        {/*  lesson.type === "module" ? (*/}
        {/*    <LessonsModule progress={progress} lessons={lessons} isCompleted={false} key={lesson.id} lesson={lesson} />*/}
        {/*  ) : (*/}
        {/*    <LessonsItem isCompleted={false} key={lesson.id} stars={progress?.[lesson.id]?.stars} lesson={lesson} />*/}
        {/*  ),*/}
        {/*)}*/}
      </Space>

      <GrammarPanel grammar={grammar} isOpen={!!grammar} toggle={setGrammar} />
    </div>
  );
};

export default Lessons;
