import ReactPlayer from "react-player";
// import YoutubePlayer from "react-player/youtube";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styles from "./VideoTask.module.scss";
import { StatusType } from "App.types";
import { Badge, Button } from "antd";
import { CheckOutlined, CommentOutlined } from "@ant-design/icons";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { SentenceTaskProps } from "../SentenceTask.type";
import sentStyles from "../SentenceTask.module.scss";
import TaskPanel from "Components/TaskPanel";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { STATIC_URL } from "App.constants";

const VideoTask: FC<SentenceTaskProps> = ({
  autoPlay = true,
  onNext,
  showComments,
  sentence: { id, medias, media, markers },
  onTaskComplete,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  // const [activeIdx, setActiveIdx] = useState(0);

  const playerRef = useRef<ReactPlayer>(null);
  // const youtubeRef = useRef<YoutubePlayer>(null);

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  // const { data } = useQuery({
  //   enabled: !!media && /^\d+$/.test(media),
  //   queryKey: ["media", media],
  //   queryFn: () => (media ? API.media.getById(media) : undefined),
  // });

  useEffect(() => {
    setStatus(StatusType.Empty);
  }, [id]);

  // const clips = useMemo(() => medias.map((m) => ({ ...m, url: `{STATIC_URL}/media/${m.url}` })), [medias]);
  const url = useMemo(() => media && !/^\d+$/.test(media) && (media.includes("http") ? media : `${STATIC_URL}/media/${media}`), [media]);
  // const youtube = useMemo(() => media && !isNaN(media) && data?.url, [data?.url, media]);

  const startTime = useMemo(() => (media?.includes("start=") ? new URL(media).searchParams.get("start") : ""), [media]);

  useEffect(() => {
    if (!isPlaying && startTime) {
      playerRef?.current?.seekTo(+startTime);
    }
  }, [startTime, isPlaying]);

  // useEffect(() => {
  //   if (data?.start) {
  //     youtubeRef?.current?.seekTo(data?.start);
  //     setIsPlaying(true);
  //   }
  // }, [data?.start]);

  const onClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      // if (activeIdx === medias.length - 1) setActiveIdx(0);
      setIsPlaying(true);
    }
  };

  return (
    <div className={cx(sentStyles.sentenceTask, styles.videoTask, { [styles.videoTask_mobile]: isMobile })}>
      <div className={styles.player} onClick={startTime ? undefined : onClick}>
        {/*{youtube && (*/}
        {/*  <YoutubePlayer*/}
        {/*    progressInterval={100}*/}
        {/*    ref={youtubeRef}*/}
        {/*    playing={isPlaying}*/}
        {/*    playsinline={true}*/}
        {/*    onProgress={onProgress}*/}
        {/*    width={isMobile ? "100%" : ""}*/}
        {/*    height={"100%"}*/}
        {/*    url={youtube}*/}
        {/*  />*/}
        {/*)}*/}

        {url && (
          <ReactPlayer
            ref={playerRef}
            onStart={() => setIsPlaying(true)}
            playing={isPlaying}
            playsinline={true}
            onEnded={() => {
              setIsPlaying(false);
              setStatus(StatusType.Completed);

              if (status !== StatusType.Completed) {
                onTaskComplete(id);
              }
              startTime && playerRef?.current?.seekTo(+startTime);
            }}
            width={isMobile ? "100%" : ""}
            height={"100%"}
            url={url}
          />
        )}

        <VideoPlayer
          showCaptions={markers.includes("subtitles")}
          medias={medias}
          autoPlay={autoPlay}
          onFinish={() => {
            setStatus(StatusType.Completed);

            if (status !== StatusType.Completed) {
              onTaskComplete(id);
            }
          }}
        />

        {/*<Button type={"link"} size={"large"} onClick={onClose} className={styles.btnClose} icon={<CloseOutlined size={28} />} />*/}
      </div>

      <TaskPanel>
        <Badge count={count} color={"blue"}>
          <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
        </Badge>
        <Button
          disabled={status !== StatusType.Completed}
          icon={<CheckOutlined />}
          className={sentStyles.btn_next}
          type={"primary"}
          shape={"round"}
          onClick={() => onNext()}
        >
          далее
        </Button>
        <PanelButtonPlaceholder />
      </TaskPanel>
    </div>
  );
};

export default VideoTask;
