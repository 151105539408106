import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Button, Flex, Progress, Skeleton } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "Api";
import { delay, flatten, last, round, uniqBy } from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import SentenceComments from "Components/SentenceComments";
import { SentenceType, StatusType, TaskType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import useProgress from "Hooks/useProgress";
import { getCompletedSlate, getNonEnglishAudioUrl, getSentenceAudioUrl } from "App.helpers";
import { BackwardFilled, PauseCircleFilled, SoundFilled } from "@ant-design/icons";
import CompleteWindow from "Components/CompleteWindow";
import Slider from "react-slick";
import SpeechLessonTask from "Components/SentenceTask/SpeechLessonTask";
import SentenceTaskHelpButton from "Components/SentenceTask/SentenceTaskHelpButton";
import { UserContext } from "App";
import { useAllTasks } from "Pages/Lessons/useAllTasks";
import styles from "./SpeechLesson.module.scss";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import useDeepgram from "Hooks/useDeepgram";
import RecordButton from "Components/RecordButton";
import { isMobile } from "react-device-detect";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SpeechLesson: FC<{ review?: boolean }> = ({ review }) => {
  const [showComments, setShowComments] = useState(false);
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [activeIdx, setActiveIdx] = useState(-1);
  const [lessonStatus, setLessonStatus] = useState<StatusType>(StatusType.Empty);
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  // const [activeSpeechIdx, setActiveSpeechIdx] = useState<number>(-1);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);
  const [hint, setHint] = useState<string>("");

  const queryClient = useQueryClient();

  const { id = "", course, sentId } = useParams();

  const navigate = useNavigate();

  const sliderRef = useRef<Slider>(null);

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const {
    story: { type, id: storyId },
  } = lesson || { story: {} };

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const onRepeat = useCallback(() => {
    setActiveIdx(0);
    setCompletedTagIdx(-1);

    API.progress.delete(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    setLessonStatus(StatusType.Empty);
  }, [id, queryClient]);

  const { progress, contextHolder, refetch: refetchProgress } = useProgress({ lesson: sentId ? undefined : lesson, onRepeat });

  const { state } = useLocation();

  const allSentences = useAllTasks(sentences, state?.allTasks);

  const completedCards = useMemo(
    () => allSentences?.filter((s) => progress.some((pr) => pr.tries === 1 && pr.sentence.id === s.id)),
    [progress, allSentences],
  );

  const tasksToComplete = useMemo<any>(() => {
    return allSentences.filter((s) => !completedCards?.some((completed) => completed?.id === s?.id));
  }, [allSentences, completedCards]);

  const allTasks = useMemo(() => [...completedCards, ...tasksToComplete], [completedCards, tasksToComplete]);

  useEffect(() => {
    if (sentId) {
      API.progress
        .delete(lesson.id)
        .then(() => {
          const order = sentences.find((s) => s.id === +sentId)?.order || -1;
          const completed = allTasks.filter((s: any, idx) => (state?.allTasks ? idx < +sentId : s?.lessonOrder < order));
          return Promise.all(
            completed.map((sent) =>
              API.progress.save({ lesson: { id: lesson.id }, tries: 1, type: sent?.task, sentence: { id: sent?.id ?? 0 } }),
            ),
          );
        })
        .then(() => navigate(`/course/${course}/lessons/speech/${lesson.id}`, { replace: true }))
        .then(() => refetchProgress());
    }
  }, [allTasks, course, lesson.id, navigate, sentId, refetchProgress, sentences, state?.allTasks]);

  useEffect(() => setActiveIdx(completedCards.length), [completedCards]);

  const activeSentence = useMemo<SentenceType | undefined>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const allTags = useMemo(() => uniqBy(flatten(allTasks.map((s) => s.tags)), "id"), [allTasks]);

  const {
    start,
    stop,
    reset,
    // transcript: textFromSpeech,
    words,
    isSpeaking,
  } = useDeepgram({ tags: allTags, setStatus: setAudioStatus });

  const percent = useMemo(() => {
    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * activeIdx, 2);
  }, [activeIdx, allTasks.length]);

  // next
  const onNext = useCallback(() => {
    if (hint) {
      return setHint("");
    }
    reset();
    setStatus(StatusType.Empty);
    // setActiveSpeechIdx(-1);
    setCompletedTagIdx(-1);

    setActiveIdx((prev) => prev + 1);

    if (activeIdx >= allTasks.length - 1) {
      stop();
      return delay(() => setLessonStatus(StatusType.Completed), 1000);
    }

    sliderRef.current?.slickNext();
  }, [activeIdx, allTasks.length, hint, reset, stop]);

  const onTaskComplete = useCallback(() => {
    // setAudioIndex(0);
    // setTries(1);
    delay(() => onNext(), 1000);

    const result = { sentence: { id: activeSentence?.id || 0 }, lesson: { id: +id }, type: activeSentence?.task, tries: 1 };
    return API.progress.save(result);
  }, [activeSentence?.id, activeSentence?.task, id, onNext]);

  useEffect(() => {
    if (progress?.find((p) => p.percent === percent) || !percent) return;

    if (activeIdx <= allTasks.length - 1) {
      API.progress.save({
        lesson: { id: +id },
        percent,
        status: "progress",
      });
    } else {
      const tasksWithErrors = uniqBy(progress?.filter((pr) => pr.tries !== 1), (pr) => `${pr.sentence.id}${pr.type}`).length;
      const stars = ((allTasks.length - tasksWithErrors) / allTasks.length) * 5;

      API.progress.save({
        lesson: { id: +id },
        percent: 100,
        stars,
        status: "completed",
      });

      setLessonStatus(StatusType.Completed);
    }
  }, [id, percent, progress, queryClient, allTasks.length, sentences.length, activeIdx]);

  const onAudioEnd = useCallback(() => delay(onNext, 500), [onNext]);

  const user = useContext(UserContext);

  const audio = useMemo(() => {
    if (!activeSentence?.text || activeSentence.task === TaskType.Grammar) return;

    const { text, speak, voice } = activeSentence;
    const result = user?.isEnglish
      ? new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[6].value, lesson.speechRate))
      : new Audio(getNonEnglishAudioUrl(speak || text.trim(), GERMAN_VOICES[0], storyId, lesson.speechRate));

    if (activeSentence.task === TaskType.Listen) {
      result.addEventListener("ended", onTaskComplete);
    }

    return result;
  }, [activeSentence, user?.isEnglish, lesson.speechRate, storyId, onTaskComplete]);

  useEffect(() => {
    if (lessonStatus === StatusType.isPlaying && activeSentence?.task === TaskType.Listen) {
      delay(() => audio?.play(), 500);
    }
  }, [activeIdx, lessonStatus, audio, activeSentence?.task]);

  const filteredRightTags = useMemo(
    () => getCompletedSlate(activeSentence?.tags || [], activeSentence?.text ?? "").filter((el) => el.word && !SkipTags.includes(el.word)),
    [activeSentence],
  );

  useSpeechCheck({
    status,
    sentence: activeSentence,
    setCompletedTagIdx,
    textFromSpeech: words.join(" "),
    completedTagIdx,
    filteredRightTags,
  });

  useEffect(() => {
    if (completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      setStatus(StatusType.Completed);
      onTaskComplete();
    }
  }, [completedTagIdx, filteredRightTags, onTaskComplete, activeSentence, stop]);

  const onPrev = useCallback(() => {
    reset();
    setCompletedTagIdx(-1);
    sliderRef.current?.slickPrev();
    setActiveIdx((prev) => prev - 1);
  }, [reset]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      setAudioStatus(StatusType.Loading);
      setCompletedTagIdx(-1);
      start();
    }
  }, [audioStatus, start, stop]);

  const pausePlay = useCallback(() => {
    if (lessonStatus === StatusType.isPlaying) {
      audio?.removeEventListener("ended", onAudioEnd);
      setLessonStatus(StatusType.Empty);
    } else {
      audio?.addEventListener("ended", onAudioEnd);
      audio?.play();
      setLessonStatus(StatusType.isPlaying);
    }
  }, [lessonStatus, audio, onAudioEnd]);

  const onHintAnswer = useCallback(() => {
    reset();
    setHint(activeSentence?.text || "");
    if (audio) {
      audio.play();
      audio.onended = () => {
        delay(() => onNext(), 2000);
      };
    } else {
      onNext();
    }
  }, [reset, activeSentence?.text, audio, onNext]);

  console.log(activeIdx);

  return (
    <div className={styles.speechLesson}>
      <div className={styles.header}>
        <Flex>
          <Progress percent={round(percent)} showInfo={false} />
          <ButtonClose path={course ? `/course/${course}` : "/"} lessonId={lesson.id} />
        </Flex>
      </div>

      {lessonStatus === "completed" ? (
        <CompleteWindow onRepeat={onRepeat} lessonId={id} course={course} />
      ) : (
        <div>
          <Skeleton active={!!sentId} loading={!!sentId}>
            <Slider centerPadding={"10px"} ref={sliderRef} centerMode vertical infinite={false} slidesToShow={5} slidesToScroll={1}>
              {allTasks.map((t: any, idx: number) => (
                <SpeechLessonTask
                  key={idx}
                  audio={idx === activeIdx ? audio : undefined}
                  isCompleted={idx < activeIdx || status === StatusType.Completed}
                  active={idx === activeIdx}
                  sentence={t}
                  onTaskComplete={onTaskComplete}
                  lesson={lesson}
                  alignCenter={true}
                  reset={reset}
                  completedTagIdx={completedTagIdx}
                  isRecording={audioStatus === StatusType.IsRecording}
                />
              ))}
              <div></div>
              <div></div>
            </Slider>
          </Skeleton>

          <div className={styles.panel}>
            <div className={styles.panel__content}>
              <Button disabled={!activeIdx} icon={<BackwardFilled />} onClick={onPrev}>
                {isMobile ? "" : "Повторить"}
              </Button>
              {activeSentence?.task === TaskType.Listen ? (
                <Button
                  type={"primary"}
                  icon={lessonStatus === StatusType.isPlaying ? <PauseCircleFilled /> : <SoundFilled />}
                  onClick={pausePlay}
                />
              ) : (
                <RecordButton
                  isRecording={audioStatus === StatusType.IsRecording}
                  isSpeaking={isSpeaking}
                  loading={audioStatus === StatusType.Loading}
                  onClick={onRecordClick}
                />
              )}

              <SentenceTaskHelpButton status={lessonStatus} openDictionary={setDictOpened} onHintAnswer={onHintAnswer} />
            </div>
          </div>
        </div>
      )}

      <Dictionary
        storyId={lesson.story.id}
        isOpen={isDictOpened}
        sentences={
          type === "card" ||
          (activeSentence?.task &&
            [TaskType.AudioSelect, TaskType.SpellerSelect, TaskType.Select, TaskType.ReverseSelect].includes(activeSentence.task))
            ? sentences
            : activeSentence
        }
        toggle={setDictOpened}
      />
      <SentenceComments isOpen={showComments} toggle={setShowComments} sentence={activeSentence} />
      <GrammarPanel isOpen={isGrammarOpened} grammar={lesson?.grammar} toggle={setGrammarOpened} />
      {contextHolder}
    </div>
  );
};

export default SpeechLesson;
