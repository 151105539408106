import { useEffect } from "react";
import { STATIC_URL } from "App.constants";
import usePrevious from "Hooks/usePrevious";

const useGrammarAudio = (content: string) => {
  const prev = usePrevious(content);

  useEffect(() => {
    if (prev === content || !content) return;

    const spans = document.getElementsByClassName("audio");

    Array.from(spans).forEach((el) => {
      const text = el.textContent?.trim();
      const audio: HTMLMediaElement | any = text && document.getElementById(text);
      audio && el.addEventListener("click", () => audio.play());
      el.innerHTML = `<style>.audio:hover {cursor: pointer; text-decoration: underline; text-decoration-color: lightgrey;}</style>
<img src="${STATIC_URL}/covers/sound.svg" alt='' style="width: 16px; margin-right: 0; opacity: 0.5" />
${el.innerHTML}`;
    });
  }, [content, prev]);
};

export default useGrammarAudio;
