import { useMemo } from "react";
import { SentenceType, TagType } from "App.types";
import { TagsToMerge } from "App.constants";
import { isArray } from "lodash";

const useMergedTags = (sentences: SentenceType[] | SentenceType) => {
  return useMemo(
    () =>
      (isArray(sentences) ? sentences : [sentences]).map((s) => ({
        ...s,
        tags: s.tags.reduce<TagType[]>((acc, tag) => {
          if (TagsToMerge.includes(tag.word) && acc[acc.length - 1]) {
            acc[acc.length - 1] = {
              ...acc[acc.length - 1],
              word: `${acc[acc.length - 1].word}${tag.word}`,
            };
            return [...acc];
          } else {
            return [...acc, tag];
          }
        }, []),
      })),
    [sentences],
  );
};

export default useMergedTags;
