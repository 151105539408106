import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SentenceType } from "App.types";
import { Col, notification, Row } from "antd";
import cx from "classnames";
import { delay, shuffle } from "lodash";
import { isMobile } from "react-device-detect";
import styles from "./StoryMatchTask.module.scss";
import { successMessage } from "App.constants";

type Props = {
  sentences: SentenceType[];
  onTaskComplete: () => void;
  isCompleted: boolean;
};

const StoryMatchTask: FC<Props> = ({ isCompleted, sentences, onTaskComplete }) => {
  const [rightAnswers, setRightAnswers] = useState<number[]>([]);
  const [selectedText, setSelectedText] = useState<SentenceType>();
  const [selectedTranslate, setSelectedTranslate] = useState<SentenceType>();

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const shuffledTexts = useMemo(() => shuffle(sentences), [sentences]);
  const shuffledTranslates = useMemo(() => shuffle(sentences), [sentences]);

  const texts = useMemo(() => shuffledTexts.filter((s) => !rightAnswers.includes(s.id)), [rightAnswers, shuffledTexts]);
  const translates = useMemo(() => shuffledTranslates.filter((s) => !rightAnswers.includes(s.id)), [rightAnswers, shuffledTranslates]);

  const rightSentences = useMemo(() => rightAnswers.map((id) => sentences.find((s) => s.id === id)), [rightAnswers, sentences]);

  const onTextClick = useCallback(
    (s: SentenceType) => {
      setSelectedText((prev) => (prev?.id === s.id ? undefined : s));

      if (s.id === selectedTranslate?.id) {
        return delay(() => {
          setRightAnswers((prev) => [...prev, s.id]);
          setSelectedText(undefined);
          setSelectedTranslate(undefined);
        }, 800);
      }

      if (selectedTranslate && s.id === selectedText?.id) {
        setSelectedTranslate(undefined);
        return setSelectedText(undefined);
      }

      if (selectedTranslate) {
        delay(() => setSelectedText(undefined), 800);
      }
    },
    [selectedText?.id, selectedTranslate],
  );

  const onTranslateClick = useCallback(
    (s: SentenceType) => {
      setSelectedTranslate((prev) => (prev?.id === s.id ? undefined : s));

      if (s.id === selectedText?.id) {
        return delay(() => {
          setRightAnswers((prev) => [...prev, s.id]);
          setSelectedText(undefined);
          setSelectedTranslate(undefined);
        }, 800);
      }

      if (selectedText && s.id === selectedTranslate?.id) {
        setSelectedTranslate(undefined);
        return setSelectedText(undefined);
      }

      if (selectedText) {
        delay(() => setSelectedTranslate(undefined), 800);
      }
    },
    [selectedText, selectedTranslate?.id],
  );

  useEffect(() => {
    if (!isCompleted && rightAnswers.length === sentences.length) {
      notifyApi.success(successMessage);
      onTaskComplete();
    }
  }, [isCompleted, notifyApi, onTaskComplete, rightAnswers.length, sentences.length]);

  return (
    <div className={styles.storyMatchTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        {rightSentences.map((s) => (
          <Row gutter={10} key={s?.id}>
            <Col span={12}>
              <div
                className={cx(styles.button, styles.button__completed, {
                  [styles.button__disabled]: selectedText,
                })}
              >
                {s?.text}
              </div>
            </Col>
            <Col span={12}>
              <div
                className={cx(styles.button, styles.button__completed, {
                  [styles.button__disabled]: selectedTranslate,
                })}
              >
                {s?.translate}
              </div>
            </Col>
          </Row>
        ))}

        <Row gutter={10}>
          <Col span={12}>
            {texts?.map((s) => (
              <div
                key={s.id}
                className={cx(styles.button, {
                  [styles.button__mobile]: isMobile,
                  [styles.button__active]: selectedText?.id === s.id,
                  [styles.button__disabled]: selectedText && selectedText?.id !== s.id,
                  [styles.button__correct]: s.id === selectedTranslate?.id && selectedText?.id === s.id,
                  [styles.button__wrong]: selectedTranslate && s?.id !== selectedTranslate?.id && selectedText?.id === s.id,
                })}
                // type={
                //   rightAnswers.includes(s.id) || (selectedText && selectedText.id !== s.id)
                //     ? "text"
                //     : selectedText?.id !== s.id
                //       ? "dashed"
                //       : "default"
                // }
                onClick={() => onTextClick(s)}
              >
                {s.text}
              </div>
            ))}
          </Col>
          <Col span={12}>
            {translates?.map((s) => (
              <div
                key={s.id}
                className={cx(styles.button, {
                  [styles.button__mobile]: isMobile,
                  [styles.button__active]: selectedTranslate?.id === s.id,
                  [styles.button__disabled]: selectedTranslate && selectedTranslate?.id !== s.id,
                  [styles.button__correct]: selectedText?.id === selectedTranslate?.id && selectedTranslate?.id === s.id,
                  [styles.button__wrong]: selectedText && selectedText?.id !== selectedTranslate?.id && selectedTranslate?.id === s.id,
                })}
                onClick={() => onTranslateClick(s)}
              >
                {s.translate}
              </div>
            ))}
          </Col>
        </Row>

        <div className={styles.bottom}></div>
      </div>

      {contextHolder}
    </div>
  );
};

export default StoryMatchTask;
