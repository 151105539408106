import { Flex, Segmented, Skeleton } from "antd";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { keepPreviousData, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { STATIC_URL } from "App.constants";
import styles from "./Book.module.scss";
import { last } from "lodash";
import usePrevious from "Hooks/usePrevious";
import { ProgressType, StatusType, TaskType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import { isMobile } from "react-device-detect";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import PageLesson from "./Page/PageLesson";
import CompleteWindow from "Components/CompleteWindow/CompleteWindow";
import useProgress from "Hooks/useProgress";

// const steps = [{ title: TaskType.Read }, { title: TaskType.Masked }];

const BookLesson: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [page] = useState<number>(1);
  const [source, setSource] = useState<string>("Оригинал");
  const [lessonStatus, setLessonStatus] = useState<StatusType>();

  const { id = "" } = useParams();

  const { data: lesson, isLoading } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id, page],
    queryFn: () => API.lesson.get(id, page),
  });

  const { story, tasks = [] } = lesson;

  const activeType = useMemo(() => tasks[activeStep], [activeStep, tasks]);

  const { data: { sentCount = 0 } = {} } = useQuery({
    queryFn: () => story && API.story.get(story.id),
    enabled: !!story,
    queryKey: ["story", story?.id],
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  const { sentences, title } = story || {};
  const lastSentences = usePrevious(sentences, { keepPrevValue: true });

  const audio = useMemo(() => story && new Audio(`${STATIC_URL}/stories/${story.title}/audio.m4a`), [story]);

  useEffect(() => {
    return () => audio?.pause();
  }, [audio]);

  const onRepeat = useCallback(() => {
    API.progress.delete(id);
    setActiveStep(0);
    setLessonStatus(undefined);
  }, [id]);

  const onNext = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    if (activeStep === tasks.length - 1) {
      API.progress.save({ lesson: { id: +id }, status: "completed", percent });
      setLessonStatus(StatusType.Completed);
    } else {
      // setPage((p) => p + 1);
      setActiveStep((lastStep) => lastStep + 1);
    }
  };

  const onContinue = useCallback(
    (progress: ProgressType) => {
      const stepIdx = tasks.findIndex((task) => task === progress.type);
      if (stepIdx === tasks.length - 1) {
        setActiveStep(0);
        // setPage((progress.page || 0) + 1);
      } else {
        // setPage(progress.page || 0);
        setActiveStep(stepIdx + 1);
      }
    },
    [tasks],
  );

  const { contextHolder: progressContext } = useProgress({ lesson, onRepeat, onContinue });

  const percent = useMemo(
    // @ts-ignore
    () => (((last(sentences)?.order || last(lastSentences)?.order) + 1) / sentCount) * 100,
    [sentCount, lastSentences, sentences],
  );

  //  const isLastPage = useMemo(() => last(sentences)?.order === sentCount - 1, [sentences, sentCount]);

  const { course = "" } = useParams();

  return (
    <div className={styles.book}>
      <Flex className={styles.header}>
        {/*<Progress percent={percent} showInfo={false} />*/}

        {/*{activeType === TaskType.Masked && isMobile ? (*/}
        {/*  <Segmented onChange={(v) => setSource(v as string)} options={["Оригинал", "Все вместе"]} />*/}
        {/*) : (*/}
        {/*  <div />*/}
        {/*)}*/}
        <ButtonClose path={`/course/${course}`} lessonId={id} />
      </Flex>
      {/*<Steps*/}
      {/*  onChange={setActiveStep}*/}
      {/*  size={isMobile ? "small" : "default"}*/}
      {/*  responsive={false}*/}
      {/*  current={activeStep}*/}
      {/*  //percent={progress}*/}
      {/*  items={steps}*/}
      {/*/>*/}

      {lessonStatus === StatusType.Completed ? (
        <CompleteWindow onRepeat={onRepeat} lessonId={id} course={course} />
      ) : (
        <>
          {activeType !== TaskType.Masked && isMobile && (
            <div className={styles.source}>
              <Segmented onChange={(v) => setSource(v as string)} options={["Оригинал", "Перевод", "Все вместе"]} />
            </div>
          )}

          <SwitchTransition>
            <CSSTransition key={`${page}${isLoading}${activeType}${source}`} timeout={500} classNames={"fade"}>
              {isLoading ? (
                <Skeleton loading active />
              ) : (
                <PageLesson
                  source={source}
                  // progressId={progress?.id}
                  lessonId={+id}
                  audio={audio}
                  sentences={sentences}
                  activeType={activeType}
                  title={title}
                  page={page}
                  onNext={onNext}
                  story={story}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        </>
      )}

      {progressContext}
    </div>
  );
};

export default BookLesson;
