import { Drawer } from "antd";
import React, { FC } from "react";
import { GrammarType } from "App.types";
import styles from "./GrammarPanel.module.scss";
import useGrammarAudio from "Hooks/useGrammarAudio";

const GrammarPanel: FC<{ grammar?: GrammarType; isOpen: boolean; toggle: Function }> = ({ grammar, isOpen, toggle }) => {
  useGrammarAudio(grammar?.content || "");

  return (
    <Drawer size={"large"} title={grammar?.title} placement="right" onClose={() => toggle(false)} open={isOpen}>
      <div className={styles.grammarPanel} dangerouslySetInnerHTML={{ __html: grammar?.content || "" }} />
    </Drawer>
  );
};

export default GrammarPanel;
