import { useEffect } from "react";
import { StatusType } from "App.types";

export const usePressEnter = ({ status, onCheck, onNext }: { onCheck?: Function; onNext?: Function; status: StatusType }) => {
  // press enter
  useEffect(() => {
    const fn = (e: KeyboardEvent) => {
      // @ts-ignore
      if (e.target?.name === "comment") return;
      if (e.key === "Enter" && status === "completed" && onNext) {
        e.preventDefault();
        e.stopPropagation();
        return onNext();
      }
      if (e.key === "Enter" && onCheck) {
        e.preventDefault();
        e.stopPropagation();
        onCheck().then();
      }
    };
    window.addEventListener("keydown", fn);

    return () => {
      window.removeEventListener("keydown", fn);
    };
  }, [onNext, status, onCheck]);
};
