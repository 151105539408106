import { Col, Row, Input, Table } from "antd";
import { useState } from "react";
import axios from "axios";
import { AnalyzerConsts } from "./Analyzer.constants";
import { API_URL } from "App.constants";

const { Search } = Input;

const Analyzer = () => {
  const [tagger, setTagger] = useState<{ word: string; lemma: string; pos: string; top: number }[]>();

  const onSearch = async (text: string) => {
    const resp = await axios.get(API_URL + "/dictionary/parse/", { params: { text } });
    // const analizedData = resp.map
    setTagger(resp.data);
  };

  return (
    <Row>
      <Col flex="1300px" style={{ margin: "20px auto" }}>
        <Search
          value="My aunt said that she would gladly go to the cinema with us because she had not seen that film and she wanted to see it very much. "
          allowClear
          size={"large"}
          onSearch={onSearch}
        />
        <h3 style={{ fontWeight: "normal", margin: "10px" }}>{tagger?.map((el) => `${el.word} `)}</h3>
        <Table size="small" dataSource={tagger} columns={AnalyzerConsts.COLUMNS} pagination={false} />;
      </Col>
    </Row>
  );
};

export default Analyzer;
