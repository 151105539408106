import UKUS from "Dictionaries/ukus.json";
import { TagType } from "App.types";
import { deburr, flatten, isEqual, isNumber, last } from "lodash";
import { API_URL, EmptySpaceTags, TagsToMerge, VOICES } from "App.constants";
import { BaseEditor, Editor, Element, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";
import API from "Api";
import wordsToNumbers from "words-to-numbers";

export const getColorByLevel = (level?: string) => {
  switch (level) {
    case "A1":
      return "#2b660e";
    case "A2":
      return "#7cb160";
    case "B1":
      return "#d9b70c";
    case "B2":
      return "#d06f27";
    case "C1":
      return "#f50";
    case "C2":
      return "#9f0000";
    default:
      return "black";
  }
};

const shortHands = {
  short: [
    "Mr.",
    "'ve",
    "'m",
    "n't",
    "'re",
    "n’t",
    "'s",
    "’s",
    "ok",
    "isn't",
    "wasn't",
    "weren’t",
    "won’t",
    "wouldn’t",
    "aren’t",
    "couldn’t",
    "didn’t",
    "hasn’t",
    "haven’t",
  ],
  full: [
    "mister",
    "have",
    "am",
    "not",
    "are",
    "not",
    "is",
    "is",
    "okay",
    "is not",
    "was not",
    "were not",
    "will not",
    "would not",
    "are not",
    "could not",
    "did not",
    "has not",
    "have not",
  ],
};

export const textIncludesAnswer = (text: string = "", answer: string = "") => {
  let cleanedText = text;
  let cleanedAnswer = answer;

  shortHands.short.forEach((short, idx) => {
    cleanedText = cleanedText.replaceAll(short, ` ${shortHands.full[idx]}`);
    cleanedAnswer = cleanedAnswer.replaceAll(short, ` ${shortHands.full[idx]}`);
  });

  cleanedText = deburr(cleanedText)
    .replace(/[^a-zа-яё0-9 ]/gi, "")
    .toLowerCase();

  cleanedAnswer = deburr(cleanedAnswer)
    .replace(/[^a-zа-яё0-9 ]/gi, "")
    .toLowerCase();

  return cleanedText.includes(cleanedAnswer);
};

export const isEqualText = (a: string = "", b: string = "") =>
  deburr(a)
    .replace(/[^a-zа-яё0-9]/gi, "")
    .toLowerCase() ===
  deburr(b)
    .replace(/[^a-zа-яё0-9]/gi, "")
    .toLowerCase();

export const isEqualEnglishWords = (a: string = "", b: string = "") => {
  const onlyLettersA = a.replace(/[^a-zа-яё0-9ÄÖÜßäüö]/gi, "").toLowerCase();
  const onlyLettersB = b.replace(/[^a-zа-яё0-9ÄÖÜßäüö]/gi, "").toLowerCase();

  if (!onlyLettersA || !onlyLettersB) return a === b;

  if (isEqualText(a, b)) return true;

  if (wordsToNumbers(onlyLettersA)?.toString() === wordsToNumbers(onlyLettersB)?.toString()) return true;

  const left = onlyLettersA.toLowerCase();
  const right = onlyLettersB.toLowerCase();
  const ukWordIdx = UKUS.ukWords.findIndex((w) => w === left);
  const usWordIdx = UKUS.usWords.findIndex((w) => w === left);

  const shortIdx = shortHands.short.findIndex((w) => w === a);
  const fullIdx = shortHands.full.findIndex((w) => w.toLowerCase() === a.toLowerCase());

  if (ukWordIdx > -1) {
    return UKUS.usWords[ukWordIdx] === right;
  }

  if (usWordIdx > -1) {
    return UKUS.ukWords[usWordIdx] === right;
  }

  if (shortIdx > -1) {
    return shortHands.full[shortIdx] === b;
  }

  if (fullIdx > -1) {
    return shortHands.short[fullIdx] === b;
  }

  return false;
};

export const withCustomLogic = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry: any) => {
    const [node] = entry;

    if (Element.isElement(node)) {
      return;
    }
    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    return;
  };

  return editor;
};

export const withCustomMaskedLogic = (editor: BaseEditor & ReactEditor & HistoryEditor, checkOnSelect = false) => {
  const { apply, deleteBackward } = editor;

  const checkUserAnswer = (node: any, path: any) => {
    const text = node.text.trim();

    if (isEqualText(text, node.answer) || node.alternatives.some((alt: string) => isEqualEnglishWords(alt, text))) {
      Transforms.setNodes(editor, { isMasked: false }, { at: path });
      return;
    }

    API.speller.checkText(text).then((spellResult) => {
      if (spellResult.some((r) => r.s.includes(node.answer))) {
        Transforms.setNodes(editor, { status: "typo" }, { at: path });
      } else {
        API.lemma.get(text).then((r) => {
          if (r.some((tag) => isEqualText(tag.lemma, node.lemma))) {
            Transforms.setNodes(editor, { status: "lemma" }, { at: path });
          } else {
            Transforms.setNodes(editor, { status: "wrong" }, { at: path });
          }
        });
      }
    });
  };

  editor.apply = async (op) => {
    // console.log(op);

    if (op.type === "merge_node") return;
    if (op.type === "move_node") return;

    // prevent only word delete but the whole sentence
    if (op.type === "remove_node" && op.node.answer) {
      return;
    }

    // @ts-ignore
    if (op.type === "set_selection" && op.newProperties?.anchor) {
      // @ts-ignore
      const { path, offset } = op.newProperties.anchor;
      const [node] = Editor.node(editor, { path, offset });

      const prevAnchor = op.properties?.anchor;
      const [prevNode] = prevAnchor ? Editor.node(editor, prevAnchor) : [];
      const text = prevNode?.text?.trim();

      if (checkOnSelect && prevNode && prevNode.isMasked && text && !isEqual(prevAnchor?.path, op.newProperties?.anchor.path)) {
        // проверка что перевод селектора не из подсказки слова моб клавиатуры
        if (op.newProperties?.anchor.offset === op.newProperties?.focus?.offset) {
          checkUserAnswer(prevNode, prevAnchor?.path);
        }
      }

      if (!node?.editable) {
        const beforeMaskedNodes = Editor.nodes(editor, {
          match: (n) => n.isMasked,
          // @ts-ignore
          at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, op.properties?.anchor?.path) },
        });

        const nodes = [];
        for (const [, firstMaskedPath] of beforeMaskedNodes) {
          nodes.push(firstMaskedPath);
        }

        const lastPath = last(nodes);
        const node = lastPath && Editor.node(editor, lastPath);
        if (node) {
          apply({
            type: "set_selection",
            // @ts-ignore
            newProperties: {
              anchor: { path: lastPath, offset: node?.[0].text.length },
              focus: { path: lastPath, offset: node?.[0].text.length },
            },
          });
        }

        return;
      }
    }

    // @ts-ignore
    if (op.type === "remove_text") {
      const [node, path] = Editor.node(editor, op.path);

      if (node && !node.editable) {
        const beforeMaskedNodes = Editor.nodes(editor, {
          match: (n) => n.isMasked,
          at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, op.path) },
        });

        let hasBefore = false;
        for (const [beforeMaskedNode, beforeMaskedPath] of beforeMaskedNodes) {
          hasBefore = true;

          // @ts-ignore
          const offset = beforeMaskedNode?.text?.length || 0;
          apply({
            type: "set_selection",
            // @ts-ignore
            newProperties: { anchor: { path: beforeMaskedPath, offset }, focus: { path: beforeMaskedPath, offset } },
          });
        }

        if (!hasBefore) {
          const firstNodes = Editor.nodes(editor, {
            match: (n) => n.isMasked,
            at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
          });

          for (const [, firstMaskedPath] of firstNodes) {
            apply({
              type: "set_selection",
              // @ts-ignore
              newProperties: { anchor: { path: firstMaskedPath, offset: 0 }, focus: { path: firstMaskedPath, offset: 0 } },
            });
            return;
          }
        }

        return;
      }

      Transforms.setNodes(editor, { ...node, status: "" }, { at: path });
    }

    if (op.type === "insert_text" && op.path) {
      const next = Editor.next(editor, { at: op.path });
      const nextMaskedNodes = Editor.nodes(editor, {
        match: (n) => n.isMasked,
        at: { anchor: Editor.start(editor, next ? next[1] : op.path), focus: Editor.end(editor, []) },
      });

      for (const [, nextMaskedPath] of nextMaskedNodes) {
        if (op.text === " ") {
          const [node] = Editor.node(editor, op.path);

          checkOnSelect && checkUserAnswer(node, op.path);
          apply({
            type: "set_selection",
            // @ts-ignore
            newProperties: { anchor: { path: nextMaskedPath, offset: 0 }, focus: { path: nextMaskedPath, offset: 0 } },
            properties: null,
          });
          return;
        }
        const words = op.text.split(" ");

        if (words.length > 1) {
          apply({ type: "insert_text", text: words[0], path: op.path, offset: op.offset });
          apply({ type: "insert_text", text: words[1], path: nextMaskedPath, offset: 0 });
          apply({
            type: "set_selection",
            // @ts-ignore
            newProperties: {
              anchor: { path: nextMaskedPath, offset: words[1].length },
              focus: { path: nextMaskedPath, offset: words[1].length },
            },
          });

          const [node] = Editor.node(editor, op.path);

          checkOnSelect && checkUserAnswer(node, op.path);

          return;
        }
      }

      const [node, path] = Editor.node(editor, op.path);
      Transforms.setNodes(editor, { ...node, status: "" }, { at: path });
    }

    apply(op);
  };

  editor.insertBreak = () => {
    return;
  };

  editor.deleteBackward = (unit) => {
    deleteBackward(unit);
  };

  editor.moveNodes = () => {
    return;
  };

  editor.mergeNodes = () => {
    return;
  };

  editor.normalizeNode = () => {
    return;
  };

  return editor;
};

export const getCompletedSlate = (tags: TagType[], text: string) => {
  let audioIdx = 0;

  return flatten(
    tags.map((tag, idx) => [
      {
        ...tag,
        text: tag.word,
        completed: false,
        audioIdx,
        skipIdx: [...EmptySpaceTags, ...TagsToMerge].includes(tag.word) ? audioIdx : audioIdx++,
      },

      !tags[idx + 1]?.word ||
      ([...EmptySpaceTags, ...TagsToMerge].includes(tags[idx + 1]?.word) && text.includes(`${tag.word}${tags[idx + 1]?.word}`))
        ? { word: "", text: "" }
        : { word: "", text: " ", audioIdx },
    ]),
  )
    .filter((el) => el.text)
    .map((el, idx) => ({ ...el, idx }));
};

export const getInitialMaskedSlate = (tags: TagType[], text = "") => {
  let index: number = 0;

  return [
    ...flatten(
      tags.map((tag, idx) => [
        idx === 0 || ([...EmptySpaceTags, ...TagsToMerge].includes(tag.word) && text.includes(`${tags[idx - 1].word}${tag.word}`))
          ? { text: "", audioIdx: index, word: "" }
          : { word: "", text: " ", audioIdx: [...EmptySpaceTags, ...TagsToMerge].includes(tag.word) ? index : index++ },
        {
          ...tag,
          lemma: tag.lemma,
          isMasked: tag.isMasked,
          text: tag.isMasked ? "" : tag.word,
          answer: tag.word,
          editable: tag.isMasked,
          audioIdx: index,
        },
      ]),
    ),
  ]
    .filter((el: any) => el.text || el.isMasked)
    .map((el, idx) => ({ ...el, idx }));
};

export const getInitialConstructorSlate = (tags: TagType[], options: number[], text: string) => {
  let index: number = 0;

  const mergedTags = tags.reduce<any[]>((acc, tag) => {
    if (TagsToMerge.includes(tag.word) && acc[acc.length - 1]) {
      acc[acc.length - 1] = {
        ...acc[acc.length - 1],
        word: `${acc[acc.length - 1].word}${tag.word}`,
      };
      return [...acc, { word: "" }];
    } else {
      return [...acc, tag];
    }
  }, []);

  return [
    ...flatten(
      mergedTags.map((tag, idx) => [
        idx === 0 || ([...EmptySpaceTags, ...TagsToMerge].includes(tag.word) && text.includes(`${tags[idx - 1].word}${tag.word}`))
          ? { text: "", audioIdx: index, word: "" }
          : { word: "", text: " ", audioIdx: [...EmptySpaceTags, ...TagsToMerge].includes(tag.word) ? index : index++ },
        {
          ...tag,
          idx,
          lemma: tag.lemma,
          isMasked: isNumber(options[idx]),
          text: isNumber(options[idx]) ? "" : tag.word,
          answer: tag.word,
          audioIdx: index,
        },
      ]),
    ),
  ].filter((el: any) => el.text || el.isMasked);
};

export const getNonEnglishAudioUrl = (text: string, voice: string, story: number | string = "", rate: string = "slow") => {
  const result = new URL(`${API_URL}/speech/nonenglish`);
  result.searchParams.append("text", text);
  result.searchParams.append("voice", voice);
  story && result.searchParams.append("story", `${story}`);
  if (rate) {
    result.searchParams.append("rate", rate);
  }

  return result.toString();
};

export const getAlternativeAudioUrl = (alterId: number, text: string, voice: string | undefined, rate: string = "slow") => {
  const result = new URL(`${API_URL}/speech/alternative`);
  result.searchParams.append("alterId", `${alterId}`);
  result.searchParams.append("voice", voice || VOICES[6].value);
  result.searchParams.append("rate", rate || "slow");
  result.searchParams.append("text", text);

  return result.toString();
};

export const getSentenceAudioUrl = (sentId: number, text: string, voice: string | undefined, rate: string = "slow") => {
  const result = new URL(`${API_URL}/speech/sent`);
  result.searchParams.append("sentId", `${sentId}`);
  result.searchParams.append("voice", voice || VOICES[6].value);
  result.searchParams.append("rate", rate || "slow");
  result.searchParams.append("text", text);

  return text ? result.toString() : "";
};

export const getAudioUrl = (text: string, lang: string, neural: string = "", storyId?: number, rate: string = "slow") => {
  const result = new URL(`${API_URL}/speech/audio`);
  result.searchParams.append("text", text.length === 1 ? text.toUpperCase() : text);
  result.searchParams.append("lang", lang);
  if (neural) {
    result.searchParams.append("rate", rate || "slow");
    result.searchParams.append("neural", neural);
  }

  storyId && result.searchParams.append("story", `${storyId}`);

  return result.toString();
};

export const getDictAudioUrl = (text: string, lang: string, storyId: number) => {
  const result = new URL(`${API_URL}/speech/dict`);
  result.searchParams.append("text", text.length === 1 ? text.toUpperCase() : text);
  result.searchParams.append("lang", lang);
  storyId && result.searchParams.append("story", `${storyId}`);

  return result.toString();
};
