import { TaskComponents } from "Components/SentenceTask";
import { GERMAN_VOICES, STATIC_URL, STORY_TASKS, TaskTypes, VOICES } from "App.constants";
import cx from "classnames";
import { LessonType, SentenceType, TaskType } from "App.types";
import StoryTask from "Components/SentenceTask/StoryTask";
import { Button, Drawer } from "antd";
import { ExportOutlined, VideoCameraTwoTone } from "@ant-design/icons";
import React, { FC, useContext, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import VideoBackground from "Components/SentenceTask/VideoBackground";
import { getNonEnglishAudioUrl, getSentenceAudioUrl } from "App.helpers";
import { UserContext } from "App";
import { useNavigate, useParams } from "react-router-dom";
import * as rdd from "react-device-detect";
import styles from "./LessonReview.module.scss";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";

const LessonReviewTask: FC<{
  idx: number;
  activeSentence: SentenceType;
  lesson: LessonType;
  sentences: SentenceType[];
  storyId: number;
  allTasks: any[];
}> = ({ allTasks, sentences, activeSentence, lesson, storyId, idx }) => {
  const [videoDrawer, setVideoDrawer] = useState<boolean>(false);
  const [audioIndex, setAudioIndex] = useState(0);

  const { id = "", course } = useParams();

  const navigate = useNavigate();

  const TaskComponent = activeSentence?.task && TaskComponents[activeSentence.task];
  const hasMovies = activeSentence?.medias.length > 1;

  const user = useContext(UserContext);

  const audios = useMemo(() => {
    if (!activeSentence?.text || activeSentence.task === TaskType.Grammar) return;

    const { text, voice, speak } = activeSentence;
    const isCard = activeSentence.isCard && !text.includes(" ");

    const rate = lesson.speechRate || "slow";
    let result: HTMLAudioElement[] = [];

    if (!user?.isEnglish) {
      const audio1 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[0], storyId, rate));
      const audio2 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[1], storyId, rate));

      audio1.addEventListener("play", () => audio2.pause());
      audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2, audio1, audio2];
    } else {
      const audio1 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[6].value, rate));
      const audio2 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[7].value, rate));

      !isCard && audio1.addEventListener("play", () => audio2.pause());
      !isCard && audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2];
    }

    const setNextIdx = () => setAudioIndex((prev) => (prev === (isCard ? 3 : 1) ? 0 : prev + 1));

    result.forEach((audio) => {
      (audio as HTMLAudioElement).addEventListener("ended", setNextIdx);
      // (audio as HTMLAudioElement).addEventListener("pause", setNextIdx);
    });

    return result;
  }, [activeSentence, lesson.speechRate, user?.isEnglish, storyId]);

  return (
    <div className={styles.task}>
      <div className={styles.lesson}>
        <Button
          type={"text"}
          size={"small"}
          onClick={() => {
            // @ts-ignore
            rdd.isMobile = false;
            navigate(`/course/${course}/lessons/exercise/${id}/${idx}`, { state: { allTasks } });
          }}
          className={styles.taskType}
        >
          <ExportOutlined /> {activeSentence?.task}
        </Button>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: activeSentence?.grammar?.title || activeSentence?.description || TaskTypes[activeSentence?.task || ""]?.desc,
          }}
        />

        <div
          className={cx(styles.content, {
            [styles.content__fullHeight]:
              lesson.story.type === "dialog" || activeSentence?.task === "grammar" || activeSentence?.linkedStoryId,
          })}
        >
          {activeSentence &&
            ((activeSentence?.task && STORY_TASKS.includes(activeSentence.task)) ||
            lesson.story.type === "dialog" ||
            (activeSentence.linkedStoryId && activeSentence.task === TaskType.Video) ? (
              <StoryTask
                activeType={activeSentence.task}
                story={activeSentence.linkedStory || lesson.story}
                lesson={lesson}
                onTaskComplete={() => ({})}
                onNext={() => ({})}
                media={activeSentence.media}
                autoPlay={false}
              />
            ) : (
              <TaskComponent
                audio={audios?.[audioIndex]}
                sentence={activeSentence}
                activeType={activeSentence?.task}
                onTaskComplete={() => new Promise(() => {})}
                lesson={lesson}
                setDictOpened={() => {}}
                showComments={() => {}}
                setTries={() => {}}
                onNext={() => {}}
                // showGrammar={lesson.grammar && setGrammarOpened}
                alignCenter
                showAnswer={activeSentence?.task === TaskType.SpellerListen}
                sentences={sentences}
                autoPlay={false}
                showSuccessMessage
                panelAbsolute
              >
                {hasMovies ? (
                  <Button onClick={() => setVideoDrawer(true)} icon={<VideoCameraTwoTone style={{ fontSize: 48 }} />} type={"link"} />
                ) : activeSentence?.medias.length === 1 ? (
                  <VideoPlayer
                    showCaptions={activeSentence.markers.includes("subtitles")}
                    medias={activeSentence.medias}
                    autoPlay={false}
                  />
                ) : activeSentence?.media?.includes(".mp3") ? (
                  <audio controls src={`${STATIC_URL}/media/${activeSentence.media}`}></audio>
                ) : (
                  <img
                    className={styles.childrenImage}
                    onError={(e: any) => (e.target.style.visibility = "hidden")}
                    src={`${STATIC_URL}/media/${activeSentence?.media}`}
                    // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
                    alt={""}
                  />
                )}
              </TaskComponent>
            ))}
        </div>
      </div>
      <Drawer
        size={"large"}
        getContainer={false}
        height={isMobile ? 280 : undefined}
        styles={{ header: { display: "none" }, body: { padding: 0 } }}
        placement={"bottom"}
        open={activeSentence?.task !== TaskType.Video && hasMovies && videoDrawer}
        onClose={() => setVideoDrawer(false)}
      >
        {activeSentence && (
          <VideoBackground
            showCaptions={activeSentence?.markers.includes("subtitles")}
            open={videoDrawer}
            onFinish={() => setVideoDrawer(false)}
            medias={activeSentence?.medias}
          />
        )}
      </Drawer>
    </div>
  );
};
export default LessonReviewTask;
