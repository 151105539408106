import { useQuery } from "@tanstack/react-query";
import { SentenceType, TaskType } from "App.types";
import { GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import API from "Api";
import { useContext } from "react";
import { UserContext } from "App";

export const useTranscripts = ({
  sentence,
  speechRate,
  audioIndex = 0,
}: {
  sentence?: SentenceType;
  audioIndex?: number;
  speechRate?: string;
}) => {
  const user = useContext(UserContext);

  const { data } = useQuery({
    enabled: !!sentence?.text && !sentence.transcripts?.length && sentence.task !== TaskType.Grammar,
    queryKey: [
      "transcript",
      sentence?.text,
      user?.isEnglish ? VOICES[audioIndex % 2 ? 7 : 6].value : GERMAN_VOICES[audioIndex % 2 ? 1 : 0],
      speechRate || "slow",
    ],
    queryFn: () =>
      API.speech.getJson(
        sentence?.text || "",
        speechRate || "slow",
        sentence?.voice || (user?.isEnglish ? VOICES[audioIndex % 2 ? 7 : 6].value : GERMAN_VOICES[audioIndex % 2 ? 1 : 0]),
      ),
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  return data;
};
