import ReactPlayer from "react-player";
import React, { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styles from "./VideoBackground.module.scss";
import { MediaType } from "App.types";
import { STATIC_URL } from "App.constants";
import VideoBackgroundPlayer from "./VideoBackgroundPlayer";

type Props = {
  open: boolean;
  onFinish: () => any;
  showCaptions?: boolean;
  medias?: MediaType[];
  media?: MediaType;
};

const VideoBackground: FC<Props & PropsWithChildren> = ({ media, medias = [], showCaptions, open, onFinish }) => {
  const [activeIdx, setActiveIdx] = useState<number>();

  const playerRef = useRef<ReactPlayer>(null);

  const clips = useMemo<any[]>(
    () => (media ? [...medias, media] : medias).map((m) => ({ ...m, url: `${STATIC_URL}/media/${m?.url}` })),
    [medias, media],
  );

  useEffect(() => {
    if (open) {
      setActiveIdx(0);
    } else {
      setActiveIdx(undefined);
    }
  }, [open]);

  useEffect(() => {
    playerRef.current?.seekTo(clips[activeIdx ?? 0].start ?? 0);
  }, [activeIdx, clips]);

  return (
    <div className={cx(styles.videoBackground, { [styles.videoTask_mobile]: isMobile })}>
      {clips.map((clip, idx) => (
        <VideoBackgroundPlayer
          key={clip.title}
          text={showCaptions ? clip.title : ""}
          url={clip.url}
          isActive={activeIdx === idx}
          start={clip.start}
          end={clip.end}
          onFinish={() => {
            setActiveIdx((prev = 0) => {
              if (prev === clips.length - 1) {
                onFinish();
                return prev;
              }

              return prev + 1;
            });
          }}
        />
      ))}
    </div>
  );
};

export default VideoBackground;
