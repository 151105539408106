import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, LessonType, SentenceType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button } from "antd";
import API from "Api";
import cx from "classnames";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { CheckOutlined } from "@ant-design/icons";
import SentenceLeaf from "../SentenceLeaf";
import { useAudioTranscript } from "../Helpers/useAudioTranscript";
import { useQuery } from "@tanstack/react-query";
import { GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import { UserContext } from "App";
import styles from "../SpeechLessonTask.module.scss";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type Props = PropsWithChildren & {
  sentence: SentenceType;
  activeType: TaskType;
  lesson: LessonType;
  // setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  // showSuccessMessage?: boolean;
  alignCenter?: boolean;
  showAnswer?: boolean;
  autoHeight?: boolean;
  active: boolean;
  textFromSpeech?: string;
  // isRecording: boolean;
  isCompleted: boolean;
  completedTagIdx: number;
};

const ConversationTaskRow: FC<Props> = ({
  active,
  sentence,
  sentence: { text, tags, translate, voice, isLeft },
  activeType,
  lesson,
  children,
  audio,
  alignCenter,
  completedTagIdx,
  isCompleted,
}) => {
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [editor, tags, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  const user = useContext(UserContext);

  const { data: transcripts } = useQuery({
    enabled: !!text,
    queryKey: ["transcript", lesson?.speechRate, text, voice],
    queryFn: () =>
      !!text
        ? API.speech.getJson(
            text,
            lesson?.speechRate || "slow",
            voice ?? (user?.isEnglish ? VOICES[isLeft ? 0 : 1].value : GERMAN_VOICES[0]),
          )
        : undefined,
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  // audio transcript
  useAudioTranscript({ transcripts, setActiveLeaf, sentence, audio });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceLeaf
        placeholder={activeType === TaskType.VoiceTranslate}
        hidden={
          [TaskType.VoiceTranslate, TaskType.Translate].includes(activeType) &&
          ((!isCompleted && !active) || (active && props.leaf.idx > completedTagIdx))
        }
        underlined={props.leaf.audioIdx === activeLeaf}
        showErrors={!isCompleted}
        {...props}
      />
    ),
    [activeType, active, isCompleted, completedTagIdx, activeLeaf],
  );

  // console.log(text, editor.children[0]);

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: !active })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable className={styles.textArea} readOnly={true} renderLeaf={renderLeaf} />
            </Slate>

            {isCompleted && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 20, color: "green" }} />}
              />
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConversationTaskRow);
