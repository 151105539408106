import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, MutableRefObject, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CustomText, LessonType, SentenceType, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button } from "antd";
import API from "Api";
import cx from "classnames";
import { withCustomLogic } from "App.helpers";
import { CheckOutlined } from "@ant-design/icons";
import SentenceLeaf from "../SentenceLeaf";
import { useAudioTranscript } from "../Helpers/useAudioTranscript";
import { useQuery } from "@tanstack/react-query";
import { GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import { UserContext } from "App";
import styles from "../SpeechLessonTask.module.scss";
import { useOnCheck } from "../Helpers/useOnCheck";
import { usePressEnter } from "../Helpers/usePressEnter";
import { delay } from "lodash";

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const initialValue = [{ children: [{ text: "" }] }];

type Props = PropsWithChildren & {
  sentence: SentenceType;
  activeType: TaskType;
  lesson: LessonType;
  // setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  showAnswer?: boolean;
  autoHeight?: boolean;
  onNext: Function;
  onCheckRef: MutableRefObject<any>;
};

const ConversationTaskTranslate: FC<Props> = ({
  sentence,
  sentence: { text, tags, translate, voice, isLeft },
  lesson,
  children,
  audio,
  onNext,
  onCheckRef,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, initialValue, { select: true });
  }, [editor]);

  useEffect(() => {
    try {
      ReactEditor.focus(editor);
    } catch (e) {}
  }, [editor]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setInitialState();
    setStatus(StatusType.Empty);
  }, [sentence.id, editor, setInitialState]);

  const user = useContext(UserContext);

  const { data: transcripts } = useQuery({
    enabled: !!text,
    queryKey: ["transcript", lesson?.speechRate, text, voice],
    queryFn: () =>
      !!text
        ? API.speech.getJson(
            text,
            lesson?.speechRate || "slow",
            voice ?? (user?.isEnglish ? VOICES[isLeft ? 0 : 1].value : GERMAN_VOICES[0]),
          )
        : undefined,
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  // audio transcript
  useAudioTranscript({ transcripts, setActiveLeaf, sentence, audio });

  const onCheck = useOnCheck({
    editor,
    activeType: TaskType.Translate,
    text,
    tags,
    id: sentence.id,
    lesson,
    alternatives: sentence.alternatives,
    //notifyApi: () =>{},
    onComplete: () => setStatus(StatusType.Completed),
    setStatus,
  });

  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (status === StatusType.Completed) {
      timeoutRef.current = delay(() => onNext(), 1000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [onNext, status]);

  useEffect(() => {
    onCheckRef.current = onCheck;
  }, [onCheck, onCheckRef]);

  usePressEnter({
    status,
    onNext: () => {
      clearTimeout(timeoutRef.current);
      onNext();
    },
    onCheck,
  });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceLeaf
        underlined={props.leaf.audioIdx === activeLeaf}
        showErrors={![StatusType.Completed, StatusType.Editing].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  // console.log(text, editor.children[0]);

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: false })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: true })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                onKeyDown={() => setStatus(StatusType.Editing)}
                className={styles.textArea}
                renderLeaf={renderLeaf}
                readOnly={status === StatusType.Completed}
              />
            </Slate>

            {status === StatusType.Completed && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 20, color: "green" }} />}
              />
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConversationTaskTranslate);
