import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Badge, Button, notification, Space } from "antd";
import API from "Api";
import { CheckOutlined, CommentOutlined, SoundOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { GERMAN_VOICES, successMessage } from "App.constants";
import SentenceTaskHelpButton from "./SentenceTaskHelpButton";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import { getAudioUrl, getNonEnglishAudioUrl } from "App.helpers";
import { shuffle } from "lodash";
import AnimateFade from "Components/AnimateFade";
import { UserContext } from "App";

const AudioSelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, wordGroup, alternatives },
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showComments,
  showGrammar,
  setTry,
  panelAbsolute,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedAnswer, setSelectedAnswer] = useState<string>();

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  const words = useMemo(() => {
    const alterWords = alternatives.filter((alt) => !alt.media && alt.text).map((alt) => alt.text);

    if (wordGroup?.title === "from sentences" && alterWords.length) return shuffle([text, ...alterWords]);

    const filtered = shuffle(wordGroup?.words.filter((w) => w !== text));
    return [text, ...filtered.slice(0, (lesson.selectLimit ?? 6) - 1)].sort();
  }, [alternatives, wordGroup?.title, wordGroup?.words, text, lesson.selectLimit]);

  // initial
  useEffect(() => {
    setStatus(StatusType.Editing);
  }, []);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete(id);
    },
    [onTaskComplete, id, notifyApi],
  );

  const user = useContext(UserContext);

  const onWordGroupClick = (t: string) => () => {
    if (t === text) {
      play?.() || audio?.play();
    } else {
      new Audio(
        user?.isEnglish
          ? getAudioUrl(t, "uk", sentence.isCard ? undefined : "true")
          : getNonEnglishAudioUrl(t, GERMAN_VOICES[0], lesson.story.id),
      ).play();
    }

    if (status === StatusType.Completed) {
      return;
    }

    setSelectedAnswer(t);

    if (text === t) {
      onComplete(false);
      return StatusType.Completed;
    }

    setTry();

    setStatus(StatusType.Error);

    API.event.save({ sentence: { id: sentence.id }, text: t, type: "mistake", task: TaskType.AudioSelect, lesson: { id: lesson.id } });
    return StatusType.Error;
  };

  const onHintAnswer = () => {
    API.event.save({ text, type: "hint", task: TaskType.AudioSelect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    setTry(true);

    onWordGroupClick(text)();
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        <div className={styles.bottom}>
          <Space wrap align={"center"} className={styles.select}>
            {words?.map((t) => (
              <Button
                className={cx({
                  [styles.btn_red]: selectedAnswer !== text && t === selectedAnswer,
                  [styles.btn_green]: selectedAnswer === text && t === selectedAnswer,
                })}
                key={t}
                onClick={onWordGroupClick(t)}
              >
                {t}
              </Button>
            ))}
          </Space>
        </div>
      </div>

      <div className={cx(styles.panel, { [styles.panel_absolute]: panelAbsolute })}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              <Badge count={count} color={"blue"}>
                <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
              </Badge>
              <AnimateFade>
                <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                  далее
                </Button>
              </AnimateFade>
            </>
          ) : (
            <>
              <PanelButtonPlaceholder />

              <Button type={"primary"} icon={<SoundOutlined />} shape={"round"} onClick={() => play?.() || audio?.play?.()}></Button>
            </>
          )}
          <SentenceTaskHelpButton
            status={status}
            showComments={showComments}
            openDictionary={setDictOpened}
            onHintAnswer={onHintAnswer}
            showGrammar={showGrammar}
          />
        </div>
      </div>

      {contextHolder}
    </div>
  );
};

export default AudioSelectTask;
