import { Badge, Collapse, Flex, Rate, Timeline, Tooltip } from "antd";
import { GrammarType, LessonType, ProgressType } from "App.types";
import { Dispatch, FC, SetStateAction, useContext, useMemo } from "react";
import { Levels, STATIC_URL } from "App.constants";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import { UserContext } from "App";
import { TimelineItemProps } from "antd/es/timeline/TimelineItem";
import { getStepColor } from "./Lessons.conts";
import styles from "./LessonsModule.module.scss";
import { BookTwoTone, CheckOutlined, CrownTwoTone } from "@ant-design/icons";

const LessonsModule: FC<{
  progress: { [p: number]: ProgressType };
  lessons: LessonType[];
  isCompleted: boolean;
  lesson: LessonType;
  isGrammar?: boolean;
  openGrammarPanel?: Dispatch<SetStateAction<GrammarType | undefined>>;
}> = ({ openGrammarPanel, isGrammar, isCompleted, progress, lessons, lesson: { level = 0, id, description } }) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const childLessons = useMemo(() => lessons.filter((l) => l.moduleId === id), [lessons, id]);

  const items: TimelineItemProps[] = childLessons.map((lesson, idx) => {
    const { story } = lesson;

    const { picture, title, type, translate, wordCount = 0 } = story || {};

    const stars = progress?.[lesson.id]?.stars;

    const isCompleted = progress?.[lesson.id]?.status === "completed";

    return {
      // dot: getStepIcon(progress?.[lesson.id]?.percent),
      color: getStepColor(progress?.[lesson.id]?.percent),
      className: cx(styles.item, {
        [styles.item_nextCompleted]:
          lessons[idx + 1] && progress?.[lessons[idx + 1].id]?.status === "completed" && progress?.[lesson.id]?.status === "completed",
      }),
      children: (
        <div
          id={`${id}`}
          className={cx(styles.lesson, { [styles.lesson_completed]: isCompleted })}
          // style={{ backgroundImage: `url(${STATIC_URL}/lessons/${story.title?.toLowerCase()}.svg)` }}
          // style={{ backgroundImage: `url(${STATIC_URL}/stories/${story.title}/images/cover.svg)` }}
          onClick={
            lesson.type === "grammar"
              ? () => openGrammarPanel?.(lesson.grammar)
              : () => navigate(`lessons/${type}/${lesson.id}`, { state: { goBack: true } })
          }
        >
          <Flex justify={"center"} className={styles.coverWrapper}>
            <img
              className={cx(styles.cover, { [styles.cover__jpg]: picture?.includes("jpg") })}
              alt={""}
              src={picture ? `${STATIC_URL}/media/${picture}` : `${STATIC_URL}/covers/${type}_${user?.lang}.svg`}
            />
          </Flex>

          {isCompleted ? (
            <CheckOutlined />
          ) : !isGrammar && wordCount ? (
            <div className={styles.lesson__desc}>
              <CrownTwoTone />
              {` ${wordCount}`}
            </div>
          ) : null}

          <div className={styles.lesson__title}>
            {lesson.grammar && <BookTwoTone />} {lesson.grammar?.title || title}
          </div>
          <div className={styles.lesson__desc}>{lesson.description || translate}</div>

          {stars && (
            <Tooltip title={"Оценка выполнения задания"}>
              <Rate className={styles.stars} allowHalf disabled defaultValue={stars} />
            </Tooltip>
          )}
        </div>
      ),
    };
  });

  return (
    <Badge.Ribbon
      rootClassName={cx(styles.lessonsModule, { [styles.mobile]: isMobile, [styles.lessonsModule__completed]: isCompleted })}
      className={cx({ [styles.ribbon__hidden]: !Levels[level]?.title })}
      style={{ fontSize: isMobile ? 12 : undefined }}
      text={Levels[level]?.title}
      color={Levels[level]?.color}
    >
      {isGrammar ? (
        <Collapse
          size={"large"}
          defaultActiveKey={0}
          //collapsible={items.length ? undefined : "disabled"}
          className={styles.collapseTitle}
          items={[{ key: 0, label: description, children: <Timeline items={items} mode={"left"} /> }]}
        />
      ) : (
        <>
          <div className={styles.title}>{description}</div>
          <Timeline items={items} mode={"left"} />
        </>
      )}
    </Badge.Ribbon>
  );
};

export default LessonsModule;
