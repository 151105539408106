import { Flex } from "antd";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { GrammarType, ProgressType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import { useParams } from "react-router-dom";
import LessonsModule from "./LessonsModule";
import LessonsItem from "./LessonsItem";
import { useMemo, useState } from "react";
import styles from "./Lessons.module.scss";
import GrammarPanel from "Components/GrammarPanel";

const LessonsGrammar = () => {
  const [grammar, setGrammar] = useState<GrammarType | undefined>(undefined);

  const { course } = useParams();

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", course],
    queryFn: () => API.lesson.getList(course),
  });

  const rootLessons = useMemo(() => lessons.filter((l) => !l.moduleId), [lessons]);

  const { data: progress } = useSuspenseQuery({
    queryKey: ["progress"],
    queryFn: () => API.progress.getList(),
    select: (data) => data.reduce<{ [id: number]: ProgressType }>((acc, value) => ({ ...acc, [value?.lesson?.id || 0]: value }), {}),
  });

  return (
    <div className={styles.lessons}>
      <Flex justify={"space-between"}>
        <h2>{course}</h2>
        <ButtonClose path={"/"} />
      </Flex>

      <Flex id={"lessons"} vertical gap={15}>
        {rootLessons.map((lesson) =>
          lesson.type === "module" ? (
            <LessonsModule
              openGrammarPanel={setGrammar}
              isGrammar
              progress={progress}
              lessons={lessons}
              isCompleted={false}
              key={lesson.id}
              lesson={lesson}
            />
          ) : (
            <LessonsItem isCompleted={false} key={lesson.id} stars={progress?.[lesson.id]?.stars} lesson={lesson} />
          ),
        )}
      </Flex>

      <GrammarPanel grammar={grammar} isOpen={!!grammar} toggle={setGrammar} />
    </div>
  );
};

export default LessonsGrammar;
