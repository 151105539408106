import ReactPlayer from "react-player";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./VideoPlayer.module.scss";
import { OnProgressProps } from "react-player/base";
import { round } from "lodash";
import { STATIC_URL } from "App.constants";
import { Skeleton } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";

type Props = {
  text?: string;
  isActive: boolean;
  url: string;
  start?: number;
  end?: number;
  onFinish: Function;
  autoPlay?: boolean;
  isPlaying: boolean;
};

const VideoPlayerItem: FC<Props & PropsWithChildren> = ({ text, isPlaying, autoPlay = true, isActive, url, start = 0, end, onFinish }) => {
  // const [isPlaying, setIsPlaying] = useState(isActive && autoPlay);
  const [duration, setDuration] = useState<number>();
  const [loading, setLoading] = useState(true);

  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (duration && isPlaying) playerRef.current?.seekTo(start);
    //setIsPlaying(isActive && autoPlay);
  }, [isActive, start, autoPlay, duration, isPlaying]);

  const onProgress = useCallback(
    (s: OnProgressProps) => {
      if (end && s.playedSeconds >= end && round(s.playedSeconds, 1) !== s.playedSeconds) {
        // playerRef?.current?.seekTo(start);
        // setIsPlaying(false);
        onFinish();
      }
    },
    [end, onFinish],
  );

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();
    if (player && duration && text?.trim()) {
      let text1 = player.textTracks[0] || player.addTextTrack("captions", "English", "en");
      text1.mode = "showing";
      text1.addCue(new VTTCue(0, 99, text));
    } else if (player?.textTracks?.length) {
      player.textTracks[0].mode = "disabled";
    }
  }, [duration, text]);

  return (
    <div style={{ display: isActive ? "block" : "none", height: isMobile ? "" : "100%", position: isActive ? "relative" : undefined }}>
      <Skeleton loading={loading} active={loading} />
      <ReactPlayer
        progressInterval={100}
        onProgress={onProgress}
        playsinline={true}
        ref={playerRef}
        onDuration={setDuration}
        onReady={() => setLoading(false)}
        onError={() => setLoading(false)}
        onEnded={() => onFinish()}
        playing={isPlaying && isActive}
        width={isMobile ? "100%" : ""}
        height={isMobile ? 280 : "100%"}
        controls={false}
        url={`${STATIC_URL}/media/${url}`}
      />
      {!isPlaying && isActive && <PlaySquareOutlined className={styles.btnPlay} />}
    </div>
  );
};

export default VideoPlayerItem;
