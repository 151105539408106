import useScript from "Components/useScript";

const YandexToken = () => {
  useScript("https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js", () => {
    // @ts-ignore
    window.YaSendSuggestToken(window.location.origin, {
      flag: true,
    });
  });
  return null;
};

export default YandexToken;
