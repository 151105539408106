import { sample } from "lodash";
import { TaskType } from "App.types";

export const GERMAN_VOICES = ["de-DE-Neural2-A", "de-DE-Neural2-B", "de-DE-Studio-B", "de-DE-Studio-C"];

export const VOICES = [
  { title: "UK Woman Studio", value: "en-GB-Studio-C" },
  { title: "UK Man Studio", value: "en-GB-Studio-B" },
  { title: "UK Woman Neural", value: "en-GB-Neural2-A" },
  { title: "UK Man Neural", value: "en-GB-Neural2-B" },
  { title: "US Woman Neural", value: "en-US-Neural2-C" },
  { title: "US Man Neural", value: "en-US-Neural2-D" },
  { title: "US Woman Studio", value: "en-US-Studio-O" },
  { title: "US Man Studio", value: "en-US-Studio-Q" },
  { title: "US Woman Ruth", value: "US Woman Ruth" },
  { title: "US Man Matthew", value: "US Man Matthew" },
  // { title: "US Woman Journey", value: "en-US-Journey-O" },
  // { title: "US Woman Journey2", value: "en-US-Journey-F" },
  // { title: "US Man Journey", value: "en-US-Journey-D" },
  { title: "UK Woman Amy", value: "UK Woman Amy" },
  { title: "UK Man Brian", value: "UK Man Brian" },
  { title: "US Woman Danielle", value: "US Woman Danielle" },
  { title: "US Man Gregory", value: "US Man Gregory" },
];

export const clientId = "63145564413-t4c5dr5h19q538trl1rj8brjt0ae8un2.apps.googleusercontent.com";

export const API_URL = process.env.REACT_APP_HOST;

export const STATIC_URL = "https://lexoid.s3.eu-central-1.amazonaws.com";
export const POS_TAGS: { [key: string]: string } = {
  NN: "noun",
  NNS: "noun",
  NNP: "proper",
  NNPS: "proper",

  VB: "verb",
  VBD: "verb",
  VBG: "verb",
  VBN: "verb",
  VBP: "verb",
  VBZ: "verb",
  MD: "modal verb",

  DT: "determiner",
  WDT: "determiner",

  JJ: "adjective",
  JJR: "adjective",
  JJS: "adjective",
  JJN: "adjective",

  RB: "adverb",
  RBR: "adverb",
  RBS: "adverb",
  WRB: "adverb",
  EX: "adverb",

  PRP: "pronoun",
  PRP$: "pronoun",
  WP: "pronoun",

  IN: "preposition",

  CC: "conjunction",

  RP: "particle",

  CD: "numeral",

  UH: "interjection",
};

export const TagsToMerge = ["'s", "n't", "'m", "'re", "’s", "n’t", "'ve", "'"];
export const EmptySpaceTags = [".", ",", ":", "'", "'", "’", "-", "!", "?", "..."];
export const ignoreTags = [
  ";",
  "″",
  "--",
  ":",
  "’",
  "‘",
  '"',
  "be",
  ".",
  ",",
  "!",
  "?",
  "",
  "a",
  "the",
  "'",
  "-",
  "–",
  "'s",
  "’s",
  "...",
  "…",
  undefined,
];
export const SkipTags = [";", "″", "’", "‘", '"', ".", ",", "!", "?", "'", "-", "–", "...", "…", "--"];

export const Levels = [
  { title: "Starter", grade: "A0", color: "green" },
  { title: "Beginner", grade: "A1", color: "blue" },
  { title: "Elementary", grade: "A2", color: "orange" },
  { title: "Intermediate", grade: "B1", color: "red" },
];

export const TaskTypes: { [type: string]: { title: string; desc: string } } = {
  [TaskType.Listen]: { title: "Listen", desc: "Прослушай текст" },
  [TaskType.Read]: {
    title: "Read",
    desc: "Прочти текст",
  },
  [TaskType.ReadByWord]: {
    title: "Read",
    desc: "Прочти текст",
  },
  [TaskType.Repeat]: {
    title: "Repeat",
    desc: "Повтори услышанное",
  },
  [TaskType.VoiceTranslate]: {
    title: "VoiceTranslate",
    desc: "Скажи на английском",
  },
  [TaskType.Dictate]: {
    title: "Dictate",
    desc: "Прослушай и напечатай текст.",
  },
  [TaskType.Translate]: {
    title: "Translate",
    desc: "Переведи текст.",
  },
  [TaskType.Reverse]: {
    title: "Translate to english",
    desc: "Переведи текст на русский.",
  },
  [TaskType.Masked]: {
    title: "Insert",
    desc: "Заполни пропуски.",
  },
  [TaskType.Select]: {
    title: "Select",
    desc: "Выбери правильный вариант.",
  },
  [TaskType.StorySelect]: {
    title: "Select",
    desc: "Подбери правильные варианты.",
  },
  [TaskType.ReverseSelect]: {
    title: "Select",
    desc: "Выбери правильный вариант.",
  },
  [TaskType.AudioSelect]: {
    title: "Select with audio",
    desc: "Послушай и выбери правильный вариант.",
  },
  [TaskType.Collect]: {
    title: "Collect",
    desc: "Собери предложение по словам.",
  },
  [TaskType.AudioCollect]: {
    title: "AudioCollect",
    desc: "Собери предложение под диктовку.",
  },
  [TaskType.Convert]: {
    title: "Convert",
    desc: "Напиши предложение по образцу.",
  },
  [TaskType.Speller]: {
    title: "Speller",
    desc: "Напиши слово по буквам.",
  },
  [TaskType.SpellerListen]: {
    title: "Speller",
    desc: "Прослушай слово по буквам.",
  },
  [TaskType.Constructor]: {
    title: "Constructor",
    desc: "Построй предложение.",
  },
  [TaskType.SpellerSelect]: {
    title: "Speller",
    desc: "Определи слово, продиктованное по буквам.",
  },
  [TaskType.MaskedDragDrop]: {
    title: "Fill the gaps",
    desc: "Заполни пропуски.",
  },
  [TaskType.SpellerCollect]: {
    title: "Spell the word",
    desc: "Собери слово из букв.",
  },
  [TaskType.StoryDragDrop]: {
    title: "",
    desc: "Расставь блоки по местам.",
  },
  [TaskType.Video]: {
    title: "",
    desc: "Посмотри видео",
  },
  [TaskType.PictureSelect]: {
    title: "",
    desc: "Прослушай и выбери правильный вариант.",
  },
  [TaskType.Match]: {
    title: "",
    desc: "Сопоставь блоки.",
  },
  [TaskType.Masked]: {
    title: "Insert",
    desc: "Заполни пропуски.",
  },
};

export const STORY_TASKS = [
  TaskType.StoryListen,
  TaskType.StoryRead,
  TaskType.StoryMasked,
  TaskType.Match,
  TaskType.StoryDragDrop,
  TaskType.MaskedDragDrop,
  TaskType.Dictionary,
];

export const successMessage = {
  message: sample(["Верно!", "Молодец!", "Отлично!", "Good job!", "Well done!", "Excellent!", "Right!"]),
  duration: 2,
};
