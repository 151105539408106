import { useCallback } from "react";
import { StatusType, TagType } from "App.types";
import { getCompletedSlate } from "App.helpers";
import { Editor, Transforms } from "slate";
import { NotificationInstance } from "antd/es/notification/interface";
import { successMessage } from "App.constants";

type Props = {
  text?: string;
  setStatus: Function;
  editor: any;
  notifyApi: NotificationInstance;
  tags: TagType[];
  onTaskComplete: Function;
  id: number;
  showSuccessMessage?: boolean;
  answer?: string;
};

export const useOnComplete = ({
  text,
  answer,
  id,
  setStatus,
  editor,
  tags,
  notifyApi,
  showSuccessMessage = true,
  onTaskComplete,
}: Props) => {
  return useCallback(
    (showMessage = showSuccessMessage) => {
      setStatus(StatusType.Completed);

      const newTextAreaData = answer ? [{ text: answer }] : getCompletedSlate(tags, text ?? "");

      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      });
      Transforms.removeNodes(editor);
      Transforms.insertNodes(editor, [{ children: newTextAreaData }]);

      if (showMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete(id);
    },
    [text, answer, showSuccessMessage, setStatus, tags, editor, onTaskComplete, id, notifyApi],
  );
};
