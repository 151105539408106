import { delay, flatten, last } from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { API_URL, GERMAN_VOICES, SkipTags, VOICES } from "App.constants";
import SentenceComments from "Components/SentenceComments";
import { SentenceType, StatusType, TaskType } from "App.types";
import { UserContext } from "App";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import { getCompletedSlate } from "App.helpers";
import useDeepgram from "Hooks/useDeepgram";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import Slider from "react-slick";
import styles from "./ConversationTask.module.scss";
import ConversationTaskRow from "./ConversationTaskRow";
import { SentenceTaskProps } from "../SentenceTask.type";
import ConversationTaskTranslate from "./ConversationTaskTranslate";
import { Button, Popconfirm, Space } from "antd";
import { BackwardFilled, BulbTwoTone, CheckOutlined, PauseCircleFilled, RollbackOutlined, SoundFilled } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import SentenceTaskHelpButton from "../SentenceTaskHelpButton";
import TaskPanel from "Components/TaskPanel";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RecordButton from "Components/RecordButton";
import HintDrawer from "Components/HintDrawer";

const ConversationTask: FC<SentenceTaskProps & { backToLesson?: Function }> = ({
  lesson,
  onNext,
  onTaskComplete,
  sentence,
  activeType,
  backToLesson,
}) => {
  const [showComments, setShowComments] = useState(false);
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();

  const story = sentence.linkedStory || { type: "", sentences: [], id: undefined };

  const { sentences } = story || {};

  const sliderRef = useRef<Slider>(null);

  const allTasks = useMemo(() => {
    return story.type === "dialog"
      ? sentences.map((s) => ({ ...s, task: s.isLeft ? TaskType.Listen : TaskType.VoiceTranslate }))
      : sentences.map((s) => ({ ...s, task: s.tasks[0] || activeType }));
  }, [activeType, sentences, story.type]);

  const activeSentence = useMemo<SentenceType>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const { start, stop, reset, words, isSpeaking } = useDeepgram({
    tags: flatten(sentences.map((s) => s.tags)),
    setStatus: setAudioStatus,
    smart_format: true,
    interim_results: false,
  });

  useEffect(() => {
    if (allTasks[activeIdx]?.hint) {
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active"));
    } else {
      setHintDrawerStatus("");
    }
  }, [activeIdx, allTasks]);

  // next
  const onNextSentence = useCallback(() => {
    reset();
    setCompletedTagIdx(-1);

    setActiveIdx((prev) => prev + 1);

    if (activeIdx >= allTasks.length - 1) {
      stop();
      onTaskComplete();
      return setStatus(StatusType.Completed);
    }

    sliderRef.current?.slickNext();
  }, [activeIdx, allTasks.length, onTaskComplete, reset, stop]);

  const onAudioEnd = useCallback(() => delay(onNextSentence, 1000), [onNextSentence]);

  const user = useContext(UserContext);

  const audio = useMemo(() => {
    if (!activeSentence?.text) return;

    const { text, voice, isLeft } = activeSentence;
    const aud = new Audio(
      user?.isEnglish
        ? `${API_URL}/speech/audio?text=${text.trim()}&story=${story.id}&voice=${voice ?? VOICES[isLeft ? 0 : 1].value}&rate=${
            lesson.speechRate || "slow"
          }`
        : `${API_URL}/speech/nonenglish?text=${text.trim()}&voice=${voice ?? GERMAN_VOICES[isLeft ? 0 : 1]}&story=${story.id}&rate=${
            lesson.speechRate || "slow"
          }`,
    );

    if (activeSentence.task === TaskType.Listen) {
      aud.addEventListener("ended", onAudioEnd);
    }

    return aud;
  }, [activeSentence, user?.isEnglish, story.id, lesson.speechRate, onAudioEnd]);

  useEffect(() => {
    if (status === StatusType.isPlaying && activeSentence?.task === TaskType.Listen) {
      delay(() => audio?.play(), 500);
    }
  }, [activeIdx, status, audio, activeSentence?.task]);

  const filteredRightTags = useMemo(
    () =>
      activeSentence &&
      getCompletedSlate(activeSentence?.tags || [], activeSentence.text).filter((el) => el.word && !SkipTags.includes(el.word)),
    [activeSentence],
  );

  useSpeechCheck({
    completedTagIdx,
    sentence: activeSentence,
    status,
    setCompletedTagIdx,
    textFromSpeech: words.join(" "),
    filteredRightTags,
  });

  useEffect(() => {
    if (completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      // onTaskComplete(sentence.id);
      delay(() => {
        setCompletedTagIdx(-1);
        onNextSentence();
      }, 1000);
    }
  }, [completedTagIdx, filteredRightTags, onTaskComplete, activeSentence?.id, onNextSentence]);

  const onPrev = useCallback(() => {
    reset();
    setCompletedTagIdx(-1);
    sliderRef.current?.slickPrev();
    setActiveIdx((prev) => prev - 1);
  }, [reset]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      setCompletedTagIdx(-1);
      // setCompletedSpeech("");
      setAudioStatus(StatusType.Loading);
      start();
    }
  }, [audioStatus, start, stop]);

  const pausePlay = useCallback(() => {
    if (status === StatusType.isPlaying) {
      audio?.removeEventListener("ended", onAudioEnd);
      setStatus(StatusType.Empty);
    } else {
      audio?.addEventListener("ended", onAudioEnd);
      audio?.play();
      setStatus(StatusType.isPlaying);
    }
  }, [status, audio, onAudioEnd]);

  const onHintAnswer = useCallback(() => {
    reset();

    if (audio) {
      audio.play();
      // audio.onended = () => {
      //   delay(() => onNextSentence(), 2000);
      // };
    } else {
      onNextSentence();
    }
  }, [reset, audio, onNextSentence]);

  const onCheckRef = useRef<Function>(null);

  return (
    <div className={styles.conversationTask}>
      <Slider centerPadding={"10px"} ref={sliderRef} centerMode vertical infinite={false} slidesToShow={3} slidesToScroll={1}>
        {allTasks.map((t: any, idx: number) =>
          t.task === TaskType.Translate && idx === activeIdx ? (
            <ConversationTaskTranslate
              key={idx}
              audio={idx === activeIdx ? audio : undefined}
              sentence={t}
              activeType={t.task}
              lesson={lesson}
              onNext={onNextSentence}
              onCheckRef={onCheckRef}
            />
          ) : (
            <ConversationTaskRow
              key={idx}
              audio={idx === activeIdx ? audio : undefined}
              active={idx === activeIdx}
              sentence={t}
              activeType={t.task}
              lesson={lesson}
              isCompleted={idx < activeIdx || (idx === activeIdx && completedTagIdx >= last<any>(filteredRightTags)?.idx)}
              alignCenter={true}
              completedTagIdx={completedTagIdx}
            />
          ),
        )}
        <div></div>
        <div></div>
      </Slider>

      {activeSentence?.task === TaskType.Translate ? (
        <TaskPanel>
          {backToLesson ? (
            isMobile ? (
              <Popconfirm title={"Вернуться в урок?"} onConfirm={() => backToLesson()}>
                <Button icon={<RollbackOutlined />} type={"link"} />
              </Popconfirm>
            ) : (
              <Button icon={<RollbackOutlined />} type={"link"} onClick={() => backToLesson()}>
                вернуться в урок
              </Button>
            )
          ) : (
            <PanelButtonPlaceholder />
          )}

          <Button shape={"round"} type={"primary"} onClick={() => onCheckRef.current?.()}>
            проверить
          </Button>
          <PanelButtonPlaceholder />
        </TaskPanel>
      ) : (
        <div className={styles.panel}>
          <div className={styles.panel__content}>
            {backToLesson ? (
              isMobile ? (
                <Popconfirm title={"Вернуться в урок?"} onConfirm={() => backToLesson()}>
                  <Button icon={<RollbackOutlined />} type={"link"} />
                </Popconfirm>
              ) : (
                <Button icon={<RollbackOutlined />} type={"link"} onClick={() => backToLesson()}>
                  вернуться в урок
                </Button>
              )
            ) : (
              <Button disabled={!activeIdx || status === StatusType.Completed} icon={<BackwardFilled />} onClick={onPrev}>
                {isMobile ? "" : "Вернуться"}
              </Button>
            )}

            {status === StatusType.Completed ? (
              <Button
                disabled={status !== StatusType.Completed}
                icon={<CheckOutlined />}
                className={cx(styles.btn_next)}
                type={"primary"}
                shape={"round"}
                onClick={() => onNext()}
              >
                далее
              </Button>
            ) : activeSentence?.task === TaskType.Listen ? (
              <Button
                type={"primary"}
                icon={status === StatusType.isPlaying ? <PauseCircleFilled /> : <SoundFilled />}
                onClick={pausePlay}
              />
            ) : (
              <Space>
                <RecordButton
                  onClick={onRecordClick}
                  isRecording={audioStatus === "isRecording"}
                  loading={audioStatus === "loading"}
                  isSpeaking={isSpeaking}
                />
                {activeSentence?.task === TaskType.VoiceTranslate && !backToLesson && (
                  <Button
                    onClick={() => setCompletedTagIdx((prev) => filteredRightTags[filteredRightTags.findIndex((t) => t.idx > prev)]?.idx)}
                    type={"text"}
                    icon={<BulbTwoTone />}
                  />
                )}
              </Space>
            )}

            <SentenceTaskHelpButton status={status} openDictionary={setDictOpened} onHintAnswer={onHintAnswer} />
          </div>
        </div>
      )}

      <HintDrawer text={allTasks[activeIdx]?.hint} open={hintDrawerStatus === "active"} onClose={() => setHintDrawerStatus("closed")} />
      <Dictionary storyId={lesson.story.id} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <SentenceComments isOpen={showComments} toggle={setShowComments} sentence={activeSentence} />
      <GrammarPanel isOpen={isGrammarOpened} grammar={lesson?.grammar} toggle={setGrammarOpened} />
    </div>
  );
};

export default ConversationTask;
