import { Button } from "antd";
import { FC } from "react";
import { AudioOutlined, MessageOutlined } from "@ant-design/icons";
import styles from "./RecordButton.module.scss";

const RecordButton: FC<{ onClick: () => any; disabled?: boolean; isRecording?: boolean; loading?: boolean; isSpeaking?: boolean }> = ({
  disabled,
  isRecording,
  loading,
  onClick,
  isSpeaking,
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    className={styles.recordButton}
    type={"primary"}
    icon={isSpeaking ? <MessageOutlined /> : <AudioOutlined />}
    shape="circle"
    data-recording={isRecording}
    loading={loading}
    data-speaking={isSpeaking}
  />
);

export default RecordButton;
