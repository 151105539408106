import { useEffect, useState } from "react";
import { SentenceType, StatusType } from "App.types";
import { isEqualEnglishWords } from "App.helpers";
import { last } from "lodash";
import { notification } from "antd";

type Props = {
  status?: StatusType;
  textFromSpeech: string;
  completedTagIdx: number;
  setCompletedTagIdx: Function;
  sentence?: SentenceType;
  filteredRightTags: any[];
};

export const useSpeechCheck = ({
  status,
  textFromSpeech,
  setCompletedTagIdx,
  completedTagIdx,
  sentence,
  filteredRightTags: tags,
}: Props) => {
  const [completedSpeech, setCompletedSpeech] = useState("");
  const [wrongCount, setWrongCount] = useState(0);

  useEffect(() => {
    setCompletedSpeech("");
    setWrongCount(0);
  }, [sentence, status]);

  useEffect(() => {
    if (wrongCount > 3) {
      const activeTag = tags[tags.findIndex((t) => t.idx > completedTagIdx)];
      if (activeTag) {
        setCompletedTagIdx(activeTag.idx);
        notification.open({
          placement: "bottom",
          duration: 1,
          closable: false,
          message: (
            <div style={{ fontSize: 18 }}>
              подсказка: <b>{activeTag.word}</b>
            </div>
          ),
        });
      }
      setWrongCount(0);
    }
  }, [completedTagIdx, setCompletedTagIdx, tags, wrongCount]);

  useEffect(() => {
    if (textFromSpeech) setWrongCount((prev) => prev + 1);
  }, [textFromSpeech]);

  useEffect(() => {
    if (!textFromSpeech || completedTagIdx >= last<any>(tags)?.idx || status === StatusType.Completed) return;

    if (
      isEqualEnglishWords(textFromSpeech, sentence?.text) ||
      sentence?.alternatives.some((alt) => !alt.wrong && isEqualEnglishWords(alt.text, textFromSpeech))
    ) {
      setCompletedTagIdx(Infinity);
    } else {
      const newText = `${textFromSpeech} `.replace(completedSpeech, "").trim();
      if (!newText) return;

      const spWords = newText.replace(".", "").replace("n't", " n't").replace("'s", " 's").replace("'m", " 'm").split(" ");

      const isCompleted = spWords.length === tags.length && spWords.every((word, idx: number) => isEqualEnglishWords(tags[idx].word, word));

      if (isCompleted) {
        setCompletedTagIdx(Infinity);
        setWrongCount(0);
      } else {
        spWords.some((_, idx) => {
          return spWords.slice(idx, spWords.length).every((word, wordIdx) => {
            const activeTag = tags[tags.findIndex((t) => t.idx > completedTagIdx) + wordIdx];

            console.log(activeTag?.word, word, isEqualEnglishWords(activeTag?.word, word) || isEqualEnglishWords(word, activeTag?.word));
            if (word && activeTag && (isEqualEnglishWords(activeTag?.word, word) || isEqualEnglishWords(word, activeTag?.word))) {
              setCompletedTagIdx(activeTag.idx);
              setWrongCount(0);
              return true;
            }
            return false;
          });
        });

        setCompletedSpeech(`${textFromSpeech} `);
      }
    }
  }, [sentence, tags, textFromSpeech, completedSpeech, status, completedTagIdx, setCompletedTagIdx]);
};
