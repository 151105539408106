import { createClient, DeepgramClient, LiveClient } from "@deepgram/sdk";
import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from "react";
import API from "Api";
import { StatusType, TagType } from "App.types";
import { keys, last, sortBy, take, uniq } from "lodash";
import { UserContext } from "App";

async function getMicrophone() {
  const userMedia = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });

  return new MediaRecorder(userMedia);
}

type Props = {
  tags?: Partial<TagType>[];
  setStatus: Dispatch<SetStateAction<StatusType>>;
  interim_results?: boolean;
  smart_format?: boolean;
};

type WordType = { confidence: number; word: string; punctuated_word: string; start: number; end: number };

const useDeepgram = ({
  setStatus,
  tags,
  smart_format = true,
  interim_results = true,
}: Props): {
  start: Function;
  stop: Function;
  reset: Function;
  transcript: string;
  words: string[];
  isSpeaking: boolean;
} => {
  const [results, setResults] = useState<{ isFinal: boolean; text: string; start: number; destroy?: boolean }[]>([]);
  const [wordResults, setWordResults] = useState<{ [start: string]: WordType & { isFinal: boolean } }>({});
  const [token, setToken] = useState<any>();
  const [isSpeaking, setIsSpeaking] = useState(false);

  const socket = useRef<LiveClient>();
  const microphone = useRef<MediaRecorder>();
  const client = useRef<DeepgramClient>();
  const hasError = useRef(false);

  const lastItem = useRef<{ last: number; isFinal: boolean | undefined }>({ last: -1, isFinal: true });

  const user = useContext(UserContext);

  const timeoutId = useRef<any>(null);

  const start = useCallback(
    async (key = "") => {
      lastItem.current = { last: -1, isFinal: true };

      setStatus(StatusType.Loading);
      setResults([]);
      setWordResults({});

      if (key) {
        client.current = await createClient(key);
      } else {
        const newToken = token || (await API.user.getDGToken());
        setToken(newToken);
        client.current = await createClient(newToken.key);
      }

      if (!socket.current || socket.current?.getReadyState() !== 1) {
        // const phrases = flatten(
        //   tags
        //     ?.filter((t) => !EmptySpaceTags.includes(t.word))
        //     .map((tag, idx) => [
        //       [...TagsToMerge, ...EmptySpaceTags].includes(tag.word) || idx === 0 ? "" : " ",
        //       tag.isHint || tag.pos?.includes("NNP") ? tag.word : "|",
        //     ]),
        // ).filter((el) => el);
        //
        // const keywords = uniq(
        //   phrases
        //     .join("")
        //     .split("|")
        //     .map((el) => el.trim())
        //     .filter((el) => el)
        //     .map((s) => `${s}:2`),
        // );

        socket.current = client.current.listen.live({
          model: "nova-2",
          language: user?.isEnglish ? "en" : "de-DE",
          smart_format,
          interim_results,
          vad_events: true,
          // search: tags?.map((t) => t.word).join(" "),
          // @ts-ignore
          keywords: uniq(tags?.filter((t) => t.isHint || t.pos?.includes("NNP")).map((t) => t.word)),
        });

        socket.current.on("SpeechStarted", () => {
          setIsSpeaking(true);
          clearTimeout(timeoutId.current);
          timeoutId.current = setTimeout(() => setIsSpeaking(false), 1000);
        });

        socket.current?.on("error", async (e) => {
          console.error("error", e);
          microphone.current?.stop();
          setToken(null);

          if (!hasError.current) {
            hasError.current = true;
            const newToken = await API.user.getDGToken();
            setToken(newToken);
            start(newToken.key);
          }
        });

        socket.current.on("open", async () => {
          console.log("client: connected to websocket");
          microphone.current = await getMicrophone();

          microphone.current.ondataavailable = (e) => {
            // console.log("voice: sent data to websocket");
            socket.current?.send(e.data);
          };

          microphone.current?.start(500);
          setStatus(StatusType.IsRecording);
          hasError.current = false;
        });

        socket.current?.on("Results", (data) => {
          const { transcript, words: dataWords } = data.channel.alternatives[0];

          transcript && console.log(transcript);

          setResults((prevResults) => {
            const lastText = last(prevResults);

            if (data.start <= lastItem.current.last && !lastItem.current.isFinal) return [];

            return lastText?.start === data.start
              ? [...take(prevResults, prevResults.length - 1), { text: transcript.trim(), start: data.start, isFinal: data.is_final }]
              : [...prevResults, { text: transcript.trim(), start: data.start, isFinal: data.is_final }];
          });

          setWordResults((prev) => {
            dataWords.forEach((wordResult: WordType) => {
              if (data.is_final) {
                keys(prev).forEach((k) => {
                  if (!prev[k]?.isFinal) {
                    delete prev[k];
                  }
                });
              }
              prev[wordResult.start] = { ...wordResult, isFinal: data.is_final };
            });
            return { ...prev };
          });
        });

        socket.current?.on("warning", (e) => console.warn(e));

        socket.current?.on("Metadata", (e) => console.log(e));

        socket.current?.on("close", () => {
          microphone.current?.stop();
          setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));
        });
      }
    },
    [setStatus, token, tags, user?.isEnglish, smart_format, interim_results],
  );

  const stop = useCallback(() => {
    microphone.current?.stop();
    socket.current?.getReadyState() === 1 && socket.current?.finish();
  }, []);

  const reset = useCallback(() => {
    // @ts-ignore
    // setResults((prev) => (last(prev) && !last(prev).isFinal ? [{ text: "", start: last(prev)?.start, destroy: true }] : []));
    setWordResults({});
    setResults((prev) => {
      lastItem.current = { last: last(prev)?.start ?? -1, isFinal: last(prev)?.isFinal };
      return [];
    });

    setWordResults({});
  }, []);

  const transcript = useMemo(
    () =>
      results
        .map((r) => r.text)
        .filter((t) => t)
        .join(" ")
        .trim() || "",
    [results],
  );

  const words = useMemo(() => sortBy(keys(wordResults)).map((k) => wordResults[k].punctuated_word), [wordResults]);

  return { start, stop, transcript, words, reset, isSpeaking };
};

export default useDeepgram;
