import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ButtonClose: FC<{ className?: string; path?: string; lessonId?: string | number; goBack?: boolean }> = ({
  goBack,
  path,
  lessonId,
  className,
}) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const defPath = pathname.includes("lessons") ? "/lessons" : "/";

  return (
    <Button
      className={className}
      size={"small"}
      type={"text"}
      icon={<CloseOutlined style={{ fontSize: 24 }} />}
      onClick={() => (goBack || state?.goBack ? navigate(-1) : navigate(path || defPath, { state: { ...state, lessonId } }))}
    />
  );
};

export default ButtonClose;
