import React, { FC, memo, useMemo } from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import styles from "./SentenceTask.module.scss";
import { SentenceTaskProps } from "./SentenceTask.type";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import useGrammarAudio from "Hooks/useGrammarAudio";
import TaskPanel from "Components/TaskPanel";

const WelcomeTask: FC<SentenceTaskProps> = ({ onTaskComplete, onNext, sentence: { grammar, id } }) => {
  const content = useMemo(
    () => (isMobile ? grammar?.content.replaceAll("</strong> - ", "</strong> - ") : grammar?.content),
    [grammar?.content],
  );

  useGrammarAudio(content || "");

  return (
    <div className={styles.sentenceTask}>
      <div
        className={cx(styles.grammar, { [styles.grammar__mobile]: isMobile })}
        dangerouslySetInnerHTML={{
          __html: content || "",
        }}
      />

      <TaskPanel justify={"center"}>
        <Button
          icon={<CheckOutlined />}
          className={styles.btn_next}
          type={"primary"}
          shape={"round"}
          onClick={() => onTaskComplete(id).then(() => onNext())}
        >
          Далее
        </Button>
      </TaskPanel>
    </div>
  );
};

export default memo(WelcomeTask);
