import { useSuspenseQueries } from "@tanstack/react-query";
import { VOICES } from "App.constants";
import axios from "axios";
import { getSentenceAudioUrl } from "App.helpers";
import { useMemo } from "react";
import { SentenceType } from "App.types";

const useBlobAudios = ({ sentences, rate }: { sentences: SentenceType[]; rate?: string }) => {
  const datas = useSuspenseQueries({
    combine: (result) => result.map((r) => r.data?.data),
    queries: sentences.map(({ id, text, speak, isLeft, voice }) => ({
      queryKey: ["audio", id, speak || text, voice || VOICES[isLeft ? 0 : 1].value, rate],
      staleTime: Infinity,
      queryFn:
        id && text
          ? () =>
              axios.get(getSentenceAudioUrl(id, speak || text.trim(), voice || VOICES[isLeft ? 0 : 1].value, rate), {
                responseType: "blob",
              })
          : () => null,
    })),
  });

  return useMemo(() => datas.map((data: any) => new Audio(URL.createObjectURL(new Blob([data], { type: "audio/mpeg" })))), [datas]);
};

export default useBlobAudios;
