import { Button, Rate, Result, Space } from "antd";
import API from "Api";
import React, { FC, useEffect, useMemo } from "react";
import { CrownTwoTone, FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { SentenceType, TagType, TaskType } from "App.types";
import { POS_TAGS, SkipTags } from "App.constants";
import { flatten, uniqBy } from "lodash";
import CountUp from "react-countup";
import styles from "./CompleteWindow.module.scss";

const customIcons: Record<number, React.ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const CompleteWindow: FC<{
  sentences?: SentenceType[];
  onRepeat?: () => any;
  lessonId: string | number;
  course?: string;
}> = ({ lessonId, course, onRepeat, sentences = [] }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const onFinish = () => {
    queryClient.invalidateQueries({ queryKey: ["wordCount"] });
    state ? navigate(-1) : navigate(course ? `/course/${course}` : "/");
  };

  const allSentences = useMemo<[SentenceType & { parentTasks: string[] }]>(
    () =>
      // @ts-ignore
      [
        ...sentences,
        ...flatten(sentences.map((sent) => sent.linkedStory?.sentences.map((s) => ({ ...s, parentTasks: sent.tasks })))).filter((s) => s),
      ],
    [sentences],
  );

  const activeTags = useMemo(() => {
    let result: TagType[] = [];

    allSentences.forEach((s) => {
      if (
        s.tasks.some((t: TaskType) => [TaskType.VoiceTranslate, TaskType.Translate].includes(t)) ||
        (s.parentTasks?.[0] === TaskType.Conversation && s.tasks.length === 0)
      ) {
        result = [...result, ...s.tags.filter((t: any) => !SkipTags.includes(t.word))];
      } else if (
        s.tasks.some(
          (t: TaskType) => [TaskType.VoiceTranslate, TaskType.Translate].includes(t) || s.parentTasks?.[0] === TaskType.StoryMasked,
        )
      ) {
        result = [...result, ...s.tags.filter((t: TagType) => t.isMasked)];
      }
    });

    return result;
  }, [allSentences]);

  const uniqWords = useMemo(
    () =>
      uniqBy(
        // @ts-ignore
        activeTags.map((t) => ({ word: t.lemma, pos: POS_TAGS[t.pos] })),
        (w) => `${w.word}${w.pos}`,
      ),
    [activeTags],
  );

  useEffect(() => {
    if (uniqWords.length) {
      API.progress.saveVocabularyProgress(uniqWords);
    }
  }, [uniqWords]);

  return (
    <Result
      className={styles.completeWindow}
      status="success"
      title="Задание выполнено!"
      subTitle={
        <>
          {uniqWords.length && (
            <div style={{ fontSize: 48 }}>
              <CountUp end={uniqWords.length} /> <CrownTwoTone className={styles.crown} />
            </div>
          )}
          Оцените задание:{" "}
          <Rate
            style={{ fontSize: 32 }}
            onChange={(score) => API.rate.save({ lesson: { id: +lessonId }, score })}
            character={({ index = 0 }) => customIcons[index + 1]}
          />
        </>
      }
      extra={
        <Space direction={"vertical"}>
          <Button type="primary" key="console" onClick={onFinish}>
            Завершить
          </Button>
          {onRepeat && (
            <Button key="buy" onClick={onRepeat}>
              Пройти еще раз
            </Button>
          )}
        </Space>
      }
    />
  );
};

export default CompleteWindow;
