import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { API_URL, GERMAN_VOICES, VOICES } from "App.constants";
import { delay, flatten } from "lodash";
import { Button, Flex, Space } from "antd";
import { StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import StoryDialogSentence from "Components/StoryDialogSentence";
import { Element, scroller } from "react-scroll";
import { UserContext } from "App";
import { BookOutlined, CheckOutlined } from "@ant-design/icons";
import cx from "classnames";
import TaskPanel from "Components/TaskPanel";
import { isMobile } from "react-device-detect";
import styles from "./DialogTask.module.scss";
import { SentenceTaskProps } from "../SentenceTask.type";
import DialogTaskOptions from "./DialogTaskOptions";
import { getSentenceAudioUrl } from "App.helpers";
import useDeepgram from "Hooks/useDeepgram";
import DialogTaskTranslate from "./DialogTaskTranslate";
import DialogTaskRead from "./DialogTaskRead";
import { useLocation } from "react-router-dom";
import HintDrawer from "Components/HintDrawer";

const DialogTask: FC<SentenceTaskProps> = ({ autoPlay = true, lesson, onTaskComplete, onNext, sentence: taskSentence }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [activeSentIdx, setActiveSentIdx] = useState<number>(-1);
  const [isDictOpened, setDictOpened] = useState(false);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();

  const location = useLocation();

  const isReview = useMemo(() => location.pathname.includes("/review"), [location]);

  const { sentences = [], id: storyId } = taskSentence.linkedStory || {};

  useEffect(() => {
    if (activeSentIdx >= 0 && activeSentIdx < sentences.length) {
      scroller.scrollTo(`${activeSentIdx}`, { smooth: true, isDynamic: true });
    }
  }, [activeSentIdx, sentences.length]);

  useEffect(() => {
    if (status === StatusType.Empty && autoPlay) setActiveSentIdx(0);
  }, [autoPlay, status]);

  const allTags = useMemo(() => flatten(sentences.map((s) => s.tags)), [sentences]);

  const { start, stop, reset, transcript: textFromSpeech, isSpeaking } = useDeepgram({ setStatus: setAudioStatus, tags: allTags });

  useEffect(() => {
    if (![TaskType.ReadByWord, TaskType.VoiceTranslate].includes(sentences[activeSentIdx]?.tasks[0])) {
      stop();
    }
  }, [activeSentIdx, sentences, stop]);

  const user = useContext(UserContext);

  const audios = useMemo(() => {
    return sentences.map((s, idx) => {
      if (!s.text) return new Audio();

      const aud = new Audio(
        user?.isEnglish
          ? getSentenceAudioUrl(s.id, s.text, s.voice ?? VOICES[s.isLeft ? 0 : 1].value, lesson.speechRate)
          : `${API_URL}/speech/nonenglish?text=${s.text.trim()}&voice=${s.voice ?? GERMAN_VOICES[s.isLeft ? 0 : 1]}&story=${storyId}&rate=${
              lesson.speechRate || "slow"
            }`,
      );
      // aud.onplay = () => setAudioStatus(StatusType.isPlaying);
      aud.onended = ({ type }) => {
        if (type === "ended" && (!s.tasks.length || s.tasks[0] === TaskType.Listen))
          setActiveSentIdx((prev) => {
            // setAudioStatus(StatusType.Empty);
            return prev <= idx ? prev + (sentences[idx + 1]?.text ? 1 : 2) : prev; // 2 - если есть пустая строка между предложениями
          });
      };
      return aud;
    });
  }, [lesson.speechRate, sentences, storyId, user?.isEnglish]);

  const activeAudio = useMemo(() => audios?.[activeSentIdx], [audios, activeSentIdx]);
  const onNextRef = useRef<any>(null);

  useEffect(() => {
    if (
      sentences[activeSentIdx] &&
      (sentences[activeSentIdx].tasks.length === 0 || sentences[activeSentIdx].tasks[0] === TaskType.Listen)
    ) {
      if (sentences[activeSentIdx].text) {
        activeAudio?.play();
      } else {
        clearTimeout(onNextRef.current);
        onNextRef.current = delay(() => setActiveSentIdx((prev) => prev + 1), 1000);
      }
    }
  }, [activeAudio, activeSentIdx, sentences]);

  useEffect(() => {
    if (activeSentIdx >= sentences.length) {
      setStatus(StatusType.Completed);
      onTaskComplete();
    }
  }, [activeSentIdx, onTaskComplete, sentences.length]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      start();
    }
  }, [audioStatus, start, stop]);

  const availableSentences = useMemo(
    () => (isReview ? sentences : sentences.slice(0, activeSentIdx + 1)),
    [sentences, activeSentIdx, isReview],
  );

  useEffect(() => {
    if (availableSentences[activeSentIdx]?.hint) {
      setHintDrawerStatus("active");
    } else {
      setHintDrawerStatus(undefined);
    }
  }, [activeSentIdx, availableSentences]);

  return (
    <div className={cx(styles.storyTask, { [styles.storyTask_mobile]: isMobile })}>
      <div className={styles.storyTask__text}>
        <Flex vertical style={{ maxWidth: 680 }} gap={5}>
          {availableSentences.map((sentence, idx) => (
            <Element name={`${idx}`} key={sentence.id}>
              <Flex justify={sentence.isLeft ? "flex-start" : "flex-end"}>
                <div style={{ maxWidth: "90%" }}>
                  {![TaskType.MultiSelect, TaskType.VoiceTranslate, TaskType.ReadByWord].includes(sentence.tasks[0]) ? (
                    <StoryDialogSentence
                      voice={sentence.voice ?? VOICES[sentence.isLeft ? 0 : 1].value}
                      showTranslate={!sentence.text}
                      speechRate={lesson.speechRate}
                      storyId={storyId}
                      hidden={activeSentIdx < idx && !isReview}
                      active={idx === activeSentIdx}
                      audio={audios?.[idx]}
                      sentence={sentence}
                    />
                  ) : sentence.tasks.includes(TaskType.ReadByWord) ? (
                    <DialogTaskRead
                      audio={audios?.[idx]}
                      voice={sentence.voice ?? VOICES[sentence.isLeft ? 0 : 1].value}
                      textFromSpeech={textFromSpeech}
                      onClickRecording={onRecordClick}
                      audioStatus={audioStatus}
                      sentence={sentence}
                      isSpeaking={isSpeaking}
                      onSuccess={() => {
                        reset();
                        delay(() => setActiveSentIdx((prev) => prev + 1), 1500);
                      }}
                    />
                  ) : sentence.tasks.includes(TaskType.VoiceTranslate) ? (
                    <DialogTaskTranslate
                      textFromSpeech={textFromSpeech}
                      onClickRecording={onRecordClick}
                      audioStatus={audioStatus}
                      sentence={sentence}
                      isSpeaking={isSpeaking}
                      onSuccess={() => {
                        reset();
                        delay(() => setActiveSentIdx((prev) => prev + 1), 1500);
                      }}
                    />
                  ) : (
                    <DialogTaskOptions
                      storyId={taskSentence.linkedStoryId}
                      sentence={sentence}
                      onSuccess={() => delay(() => setActiveSentIdx((prev) => prev + 1), 1500)}
                    />
                  )}
                </div>
              </Flex>
            </Element>
          ))}
        </Flex>
      </div>

      <TaskPanel>
        <Button style={{ visibility: "hidden" }}></Button>

        <Space>
          <Button
            disabled={status !== StatusType.Completed}
            icon={<CheckOutlined />}
            className={cx(styles.btn_next)}
            type={"primary"}
            shape={"round"}
            onClick={() => onNext()}
          >
            далее
          </Button>
        </Space>
        <Button icon={<BookOutlined />} className={styles.btn_play} onClick={() => setDictOpened(true)} />
      </TaskPanel>

      <Dictionary storyId={storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <div className={styles.placeholder} />
      <HintDrawer
        text={sentences[activeSentIdx]?.hint}
        open={hintDrawerStatus === "active"}
        onClose={() => setHintDrawerStatus("closed")}
      />
    </div>
  );
};

export default DialogTask;
