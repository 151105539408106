// @ts-nocheck
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import { StatusType, TagType } from "App.types";
import API from "Api";
import { RealtimeTranscriber } from "assemblyai";

function useAssemblyRecognizer({
  tags,
  setStatus,
  timeout = 70000,
}: {
  timeout?: number;
  tags?: TagType[];
  setStatus: Dispatch<SetStateAction<StatusType>>;
}): {
  start: Function;
  stop: Function;
  reset: Function;
  transcript: string;
  words: string[];
} {
  const [result, setResult] = useState("");
  const [token, setToken] = useState("");
  const [finalResults, setFinalResults] = useState<string[]>([]);

  const [words] = useState<string[]>([]);

  const realtimeTranscriber = useRef<any>(null);
  const recorder = useRef<RecordRTC | null>(null);
  const timeoutRef = useRef<any>(null);

  const reset = useCallback(() => {
    setResult("");
    setFinalResults([]);
    // if (recorder.current?.getState() === "recording") {
    //   recorder.current?.reset();
    //   recorder.current?.startRecording();
    // }
  }, []);

  useEffect(() => {
    API.user.getRecToken().then(({ token }) => setToken(token));
  }, []);

  // const rt = useMemo(() => {
  //   if (!token) return;
  //
  //   const params = new URLSearchParams();
  //   params.append("token", token);
  //   params.append("sample_rate", "16000");
  //
  //   const phrases = flatten(
  //     tags?.map((tag, idx) => [
  //       [...TagsToMerge, ...EmptySpaceTags].includes(tag.word) || idx === 0 ? "" : " ",
  //       tag.isHint ? tag.word : "|",
  //     ]),
  //   ).filter((el) => el);
  //
  //   const uniqTexts = uniq(
  //     phrases
  //       .join("")
  //       .split("|")
  //       .map((el) => el.trim())
  //       .filter((el) => el),
  //   );
  //
  //   return new RealtimeService({ token, sampleRate: 16000, wordBoost: uniqTexts });
  // }, [token, tags]);

  const start = useCallback(async () => {
    setResult("");
    setFinalResults([]);

    realtimeTranscriber.current = new RealtimeTranscriber({
      token,
      sampleRate: 16_000,
      wordBoost: ["Tallinn"],
    });

    const texts: any = {};
    realtimeTranscriber.current.on("transcript", (transcript: any) => {
      let msg = "";
      texts[transcript.audio_start] = transcript.text;
      const keys = Object.keys(texts);
      // @ts-ignore
      keys.sort((a, b) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`;
          console.log(msg);
        }
      }
      // setTranscript(msg);
    });

    realtimeTranscriber.current.on("error", (event: any) => {
      console.error(event);
      setStatus(StatusType.Editing);
      realtimeTranscriber.current.close();
      realtimeTranscriber.current = null;
    });

    realtimeTranscriber.current.on("close", (code: any, reason: any) => {
      console.log(`Connection closed: ${code} ${reason}`);
      setStatus((prevState) => (prevState === StatusType.Completed ? prevState : StatusType.Editing));
      realtimeTranscriber.current = null;
    });

    await realtimeTranscriber.current.connect();

    // rt?.on("transcript", (message) => {
    //   // console.error(message);
    //   message.words?.length && setWords(message.words.map((w: any) => w.text));
    //   if (message.message_type === "FinalTranscript") {
    //     setFinalResults((prev) => [...prev, message.text]);
    //     setResult("");
    //   } else {
    //     message.text && setResult(message.text);
    //   }
    // });
    //
    // rt?.on("error", (event) => {
    //   console.error(event);
    //   recorder.current?.stopRecording();
    //   setStatus(StatusType.Editing);
    // });
    //
    // rt?.on("close", (event) => {
    //   clearTimeout(timeoutRef.current);
    //   recorder.current?.stopRecording();
    //   setStatus((prevState) => (prevState === StatusType.Completed ? prevState : StatusType.Editing));
    // });

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        recorder.current = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/webm;codecs=pcm",
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: async (blob) => {
            if (!realtimeTranscriber.current) return;
            const buffer = await blob.arrayBuffer();
            realtimeTranscriber.current.sendAudio(buffer);
          },
        });
        recorder.current.startRecording();

        setStatus(StatusType.IsRecording);
      })
      .catch((err) => console.error(err));
  }, [setStatus, token]);

  const stop = useCallback(async () => {
    clearTimeout(timeoutRef.current);
    setStatus(StatusType.Editing);

    await realtimeTranscriber.current.close();
    realtimeTranscriber.current = null;

    recorder.current?.pauseRecording();
    recorder.current = null;
  }, [setStatus]);
  //

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(stop, timeout);

    return () => clearTimeout(timeoutRef.current);
  }, [result, finalResults, stop, timeoutRef, timeout]);

  const transcript = useMemo(() => finalResults.join("") + result, [finalResults, result]);

  return { start, stop, transcript, words, reset };
}

export default useAssemblyRecognizer;
