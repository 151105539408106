import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { BookOutlined, BulbOutlined, QuestionOutlined, SoundOutlined } from "@ant-design/icons";
import { StatusType } from "App.types";
import { Button, Dropdown } from "antd";

type Props = {
  status: StatusType;
  play?: () => any;
  audio?: HTMLAudioElement;
  showComments?: Dispatch<SetStateAction<boolean>>;
  showGrammar?: Dispatch<SetStateAction<boolean>>;
  openDictionary: Dispatch<SetStateAction<boolean>>;
  onHintAnswer: Function;
};

const SentenceTaskHelpButton: FC<Props> = ({ onHintAnswer, play, audio, status, openDictionary, showGrammar }) => {
  const items = useMemo(() => {
    return [
      (audio || play) && { key: 2, label: "сказать", icon: <SoundOutlined />, onClick: () => play?.() || audio?.play() },
      { key: 0, label: "словарь", icon: <BookOutlined />, onClick: () => openDictionary(true) },
      status !== StatusType.Completed && {
        key: 1,
        label: "подсказать",
        icon: <BulbOutlined />,
        onClick: onHintAnswer,
      },
      showGrammar && {
        key: 3,
        label: "грамматика",
        icon: <BookOutlined />,
        onClick: () => showGrammar?.(true),
      },
    ].filter((el) => el);
  }, [status, showGrammar, onHintAnswer, openDictionary, play, audio]);

  return (
    <Dropdown
      menu={{
        // @ts-ignore
        items,
      }}
      trigger={["click"]}
    >
      <Button icon={<QuestionOutlined />} size={"large"} />
    </Dropdown>
  );
};

export default SentenceTaskHelpButton;
