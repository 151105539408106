import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StatusType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { ignoreTags, SkipTags } from "App.constants";
import { Badge, Button } from "antd";
import API from "Api";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { AudioOutlined, BookOutlined, CheckOutlined, CommentOutlined, SoundFilled, SoundTwoTone } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import useDeepgram from "Hooks/useDeepgram";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import { last } from "lodash";

const initialValue = [{ children: [{ text: "" }] }];

const VoiceTranslateTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags = [], translate },
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showComments,
  alignCenter = false,
  panelAbsolute,
  transcripts,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  // const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  const { start, stop, transcript: textFromSpeech } = useDeepgram({ tags, setStatus, interim_results: true, smart_format: false });

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];
    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [editor, tags, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        placeholder
        hidden={status !== StatusType.Completed && props.leaf.idx >= completedTagIdx + 1}
        underlined={props.leaf.audioIdx === activeLeaf}
        wrong={status === "error" && props.leaf.idx === (activeLeaf ?? 0) + 1 && !ignoreTags.includes(props.leaf.text)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, completedTagIdx, activeLeaf],
  );

  const filteredRightTags = useMemo(
    () => getCompletedSlate(tags, text).filter((el) => el.word && !SkipTags.includes(el.word)),
    [tags, text],
  );

  useEffect(() => {
    if (completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      stop();
      setStatus(StatusType.Completed);
      onTaskComplete(sentence.id);
      // setActiveSpeechIdx(-1);
    }
  }, [completedTagIdx, filteredRightTags, onTaskComplete, sentence.id, stop]);

  useSpeechCheck({ status, sentence, setCompletedTagIdx, textFromSpeech, completedTagIdx, filteredRightTags });

  const onRecordClick = () => {
    if (status === StatusType.IsRecording) {
      stop();
    } else {
      // setCompletedSpeech("");
      // setActiveSpeechIdx(0);
      setCompletedTagIdx(-1);
      setStatus(StatusType.Loading);
      start();
    }
  };

  const onSkip = useCallback(() => {
    onTaskComplete(sentence.id).then(() => onNext());
  }, [onNext, onTaskComplete, sentence.id]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                className={styles.textArea}
                readOnly
                onKeyDown={() => setStatus(StatusType.Editing)}
                renderLeaf={renderLeaf}
                //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
              />
            </Slate>
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>

        <div className={styles.bottom}>
          <Button
            style={{ visibility: status !== StatusType.Completed ? "hidden" : undefined }}
            icon={<SoundFilled />}
            onClick={() => play?.() || audio?.play()}
          />
        </div>
      </div>

      <div className={cx(styles.panel, { [styles.panel_absolute]: panelAbsolute })}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              <Badge count={count} color={"blue"}>
                <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
              </Badge>
              <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                далее
              </Button>
              <PanelButtonPlaceholder />
            </>
          ) : (
            <>
              <Button icon={<SoundTwoTone />} className={styles.btn_play} onClick={() => play?.() || audio?.play()} />
              <span className={styles.buttons}>
                <Button
                  onClick={onRecordClick}
                  className={styles.record}
                  type={"primary"}
                  icon={<AudioOutlined />}
                  shape="circle"
                  color={"red"}
                  data-recording={status === "isRecording"}
                  loading={status === "loading"}
                />
                <Button className={styles.btn_skip} size={"small"} type={"link"} onClick={onSkip}>
                  далее
                </Button>
              </span>
              <Button icon={<BookOutlined />} className={styles.btn_play} onClick={() => setDictOpened(true)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceTranslateTask;
