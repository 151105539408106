import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SentenceType, StatusType, StoryType } from "App.types";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor, Editor, Transforms } from "slate";
import { successMessage } from "App.constants";
import { delay, findIndex, last } from "lodash";
import { Button, Dropdown, notification, Skeleton } from "antd";
import { BookOutlined, BulbOutlined, PauseOutlined, QuestionOutlined, SoundFilled } from "@ant-design/icons";
import { isEqualEnglishWords, isEqualText, withCustomMaskedLogic } from "App.helpers";
import Dictionary from "Components/Dictionary";
import API from "Api";
import PageMaskedLeaf from "./PageMaskedLeaf";
import { getMaskedSlateForPage } from "./Page.helpers";
import styles from "./PageLesson.module.scss";
import "./Page.css";
import PageMaskedElement from "./PageMaskedElement";
import useMergedTags from "Components/useMergedTags";

export type CustomText = {
  word: string;
  text: string;
  lemma: string;
  answer: string;
  id?: number;
  sentIdx: number;
  status?: string;
  idx: number;
  isMasked?: boolean;
  alternatives: string[];
};

type Props = {
  story: StoryType;
  sentences: SentenceType[];
  onTaskComplete: () => void;
  audio?: HTMLAudioElement;
  onNext: Function;
  showTranslate?: boolean;
  showTooltip?: boolean;
};

const StoryMaskedTask: FC<Props> = ({ showTooltip, showTranslate = true, onNext, story, sentences, onTaskComplete, audio }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Error);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  // const [statuses, setStatuses] = useState<{ [idx: number]: StatusType }>({});
  const [, setScore] = useState(0);
  const [isDictOpened, setDictOpened] = useState(false);
  const [activeSent, setActiveSent] = useState<number>();
  const [activeLeaf, setActiveLeaf] = useState<number>(-1);
  const [initialValue, setInitialValue] = useState<any>();

  const editor = useMemo(() => withReact(withCustomMaskedLogic(createEditor(), true)), []);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const mergedSentences = useMergedTags(sentences);

  // initial slate
  useEffect(() => {
    setActiveSent(undefined);

    setStatus(StatusType.Error);

    // Transforms.insertNodes(editor, getMaskedSlateForPage(sentences));
    setInitialValue([
      {
        children: getMaskedSlateForPage(
          mergedSentences.map((s) => ({ ...s, wrap: true })),
          showTranslate,
          true,
        ),
        type: "parent",
      },
    ]);
  }, [editor, mergedSentences, story.type, showTranslate]);

  useEffect(() => {
    audio?.addEventListener("play", () => setAudioStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setAudioStatus(StatusType.Error));
    audio?.addEventListener("ended", () => setAudioStatus(StatusType.Completed));

    return () => audio?.pause();
  }, [audio]);

  const renderMaskedLeaf = useCallback(
    (props: any) => (
      <PageMaskedLeaf
        completed={status === StatusType.Completed}
        showTooltip={showTooltip && !showTranslate}
        underlined={props.leaf.idx <= (activeLeaf ?? -1) && props.leaf.sentIdx === activeSent}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf, activeSent, showTranslate, showTooltip],
  );

  const maskedCheck = useCallback(
    async (isHintClicked = false) => {
      console.log("onCheck");
      const maskedNodes = Editor.nodes<CustomText>(editor, {
        match: (n) => n.isMasked,
        at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
      });

      const answers = [];
      for (const [node, maskedPath] of maskedNodes) {
        answers.push({ ...node, path: maskedPath });
      }
      const spellResults = await API.speller.get(answers);
      const lemmas = (await API.lemma.get(answers.map((el) => el.text).join(","))).filter((el) => el.word !== ",");

      let hasErrors = false;
      answers.forEach((child, idx) => {
        let wordStatus = "";
        if (isEqualEnglishWords(child.text, child.answer) || child.alternatives.some((alt) => isEqualEnglishWords(alt, child.text))) {
        } else if (child.isMasked && child.text === "") {
          wordStatus = "empty";
        } else if (spellResults[idx][0]?.s.includes(child.answer) || spellResults[idx][0]?.s.includes(child.lemma)) {
          notifyApi.error({ message: <b>{child.text}</b>, description: "слово содержит опечатки" });
          wordStatus = "typo";
        } else if (isEqualText(lemmas[idx]?.lemma, child.lemma)) {
          notifyApi.warning({ message: <b>{child.text}</b>, description: "неверная форма слова" });
          wordStatus = "lemma";
        } else {
          wordStatus = "wrong";
        }
        if (wordStatus) {
          setStatus(StatusType.Error);
          hasErrors = true;
        }
        Transforms.setNodes(editor, { status: wordStatus }, { at: child.path });
      });

      if (!hasErrors) {
        onTaskComplete();
        setStatus(StatusType.Completed);
        notifyApi.success(successMessage);
      } else {
        !isHintClicked && notifyApi.error({ message: "Есть ошибки!" });
      }

      return hasErrors;
    },
    [editor, onTaskComplete, notifyApi],
  );

  const onMaskedHint = async () => {
    await maskedCheck(true);
    // API.event.save({ text, type: "hint", task: activeType, lesson: { id: lessonId }, sentence });
    const maskedNodes = Editor.nodes<CustomText>(editor, {
      match: (n) => n.isMasked && n.status !== "",
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
    });

    for (const [node, maskedPath] of maskedNodes) {
      Transforms.insertText(editor, node.answer || "", { at: maskedPath });
      Transforms.setNodes(editor, { status: "hint" }, { at: maskedPath });

      delay(() => Transforms.setNodes(editor, { status: "" }, { at: maskedPath }), 2000);
      setScore((prevState) => prevState && prevState - 10);

      return;
    }
  };

  const play = (
    from: number = sentences[0].transcripts[0].start,
    to: number = last(last(sentences)?.transcripts)?.end || 0,
    playOne: boolean = false,
  ) => {
    if (!audio) return;
    audio.currentTime = !audio.currentTime || audio.currentTime >= to || playOne ? from : audio.currentTime;
    audio.play();

    audio.ontimeupdate = ({ target: { paused } }: any) => {
      const { currentTime } = audio;
      // console.log("onTimeUpdate", currentTime, paused);

      if (currentTime >= to || paused) {
        audio.pause();
        return delay(() => {
          if (playOne) setActiveSent(undefined);
          if (!paused && !playOne && currentTime) {
            setActiveSent(sentences.length);
            setStatus(StatusType.Completed);
          }
          setActiveLeaf(-1);
        }, 500);
      }

      let activeIdx = sentences.findIndex(
        ({ transcripts }) => transcripts[0]?.start <= currentTime && (last(transcripts)?.end || 0) >= currentTime,
      );
      if (activeIdx === -1 && !playOne) {
        activeIdx = sentences.findIndex(({ transcripts }) => transcripts[0]?.start >= currentTime);
      }

      if (activeIdx >= 0) {
        setActiveSent(activeIdx);
        const wordIdx = findIndex(sentences[activeIdx].transcripts, (el) => el.end >= currentTime);
        setActiveLeaf(wordIdx);
      }
    };
  };

  const onKeyDownMasked: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { selection } = editor;
    // const node = selection && Editor.node(editor, selection);

    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      const next = Editor.next(editor, { at: selection?.focus });
      const nextMaskedNodes = Editor.nodes<CustomText>(editor, {
        match: (n) => n.isMasked,
        at: { anchor: Editor.start(editor, next?.[1] || []), focus: Editor.end(editor, []) },
      });

      for (const [nextMaskedNode, nextMaskedPath] of nextMaskedNodes) {
        //scroller.scrollTo(`${nextMaskedNode.sentIdx}`, { offset: -200, smooth: true, duration: 1000 });

        return Transforms.setSelection(editor, {
          anchor: { path: nextMaskedPath, offset: nextMaskedNode.text.length },
          focus: { path: nextMaskedPath, offset: nextMaskedNode.text.length },
        });
      }

      maskedCheck();
    }
  };

  return (
    <div className={styles.pageLesson}>
      <div className={styles.page__text}>
        {initialValue ? (
          <Slate editor={editor} initialValue={initialValue}>
            <Editable
              className={styles.textArea}
              readOnly={status === "completed"}
              onKeyDown={onKeyDownMasked}
              renderLeaf={renderMaskedLeaf}
              renderElement={(props) => <PageMaskedElement dontShowSelected {...props} />}
            />
          </Slate>
        ) : (
          <Skeleton loading />
        )}
      </div>

      <div className={styles.panel}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              {audioStatus === StatusType.isPlaying ? (
                <Button icon={<PauseOutlined />} onClick={() => audio?.pause()} />
              ) : (
                <Button icon={<SoundFilled />} onClick={() => play()} />
              )}

              <Button className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                Далее
              </Button>

              <Dropdown
                menu={{
                  items: [{ key: 0, label: "словарь", icon: <BookOutlined />, onClick: () => setDictOpened(true) }],
                }}
                trigger={["click"]}
              >
                <Button icon={<QuestionOutlined />}></Button>
              </Dropdown>
            </>
          ) : (
            <>
              <Button style={{ visibility: "hidden", width: 55 }} />
              <Button type={"primary"} shape={"round"} onClick={() => maskedCheck()}>
                проверить
              </Button>
              <Dropdown
                menu={{
                  items: [
                    { key: 0, label: "словарь", icon: <BookOutlined />, onClick: () => setDictOpened(true) },
                    {
                      key: 1,
                      label: "подсказать",
                      icon: <BulbOutlined />,
                      onClick: onMaskedHint,
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button icon={<QuestionOutlined />}></Button>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      <div className={styles.placeholder} />
      {contextHolder}
      <Dictionary storyId={story.id} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
    </div>
  );
};

export default StoryMaskedTask;
