const COLUMNS = [
  { title: "word", dataIndex: "word", key: "word" },
  { title: "lemma", dataIndex: "lemma", key: "lemma" },
  { title: "pos", dataIndex: "pos", key: "pos" },
  { title: "A1C2", dataIndex: "level", key: "level" },
  { title: "freq", dataIndex: "rank", key: "rank" },
  { title: "translate", dataIndex: "translate", key: "translate" },
];

export const AnalyzerConsts = {
  COLUMNS,
};
